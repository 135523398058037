import React, { useEffect, useRef, useState, FC } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import CommentsList from './components/CommentsList';
import CreateComment from './components/CreateComment';

import { IComment } from '../../../types/bite';
import { biteShareUserSelector } from '../../../store/biteShareUser/biteShareUser.selectors';
import { selectedBiteSelector } from '../../../store/bite/bite.selectors';
import Clouds from '../../../assets/icons/illustration.svg';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import { getIsRtl } from '../../../locale/i18n';
import gtmTrack from '../../../services/googleTagManager/track';
import useQueryParams from '../../../hooks/useQueryParams';
import CustomIntercomLauncher from '../../../components/shared/CustomIntercomLauncher';
import { isAuthenticatedSelector } from '../../../store/auth/auth.selectors';
import useGoToNext from '../GoNextBite/hooks/useGoToNext';

interface IProps {
  comments: IComment[];
}

const Discussion: FC<IProps> = ({ comments }) => {
  const { selectedBite } = useSelector(selectedBiteSelector);
  const { prefixedT } = useCustomTranslation('screens.viewBite.Discussion');
  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;
  const hideHeader = queryParams?.hideHeader;

  const isPreview = queryParams?.isPreview;
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const { shouldRedirectToHome } = useGoToNext();

  const commentsEndRef = useRef(null);
  const componentJuseMounted = useRef(true);
  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedComment, setSelectedComment] = useState<IComment>(null);

  const createReplyOnComment = (item) => {
    inputRef.current.focus();
    setSelectedComment(item);
  };

  const scrollToBottom = () => {
    commentsEndRef.current.scrollIntoView();
  };

  useEffect(() => {
    if (componentJuseMounted.current) {
      componentJuseMounted.current = false;
    } else {
      scrollToBottom();
    }
  }, [comments]);

  const biteShareUser = useSelector(biteShareUserSelector);

  useEffect(() => {
    gtmTrack('discussion_section', {
      bite_id: selectedBite?.bite,
      bite_share_id: selectedBite?.id,
      content_org_id: selectedBite?.orgid,
      sharing_mode: selectedBite?.sharing_mode,
      playlist_id: playlistId,
    });
  }, [playlistId, selectedBite]);

  const emptyStateRender = (
    <S.EmptyState hideHeader={hideHeader}>
      <S.EmptyImage src={Clouds} alt='No comments' />
      <S.EmptyTitle>{prefixedT('emptyTitle')}</S.EmptyTitle>
      <S.EmptySubTitle>{prefixedT('emptySubTitle')}</S.EmptySubTitle>
    </S.EmptyState>
  );

  return (
    <S.Container>
      <S.CommentsSection>
        {comments.length > 0 ? (
          <CommentsList comments={comments} createReplyOnComment={createReplyOnComment} />
        ) : (
          emptyStateRender
        )}
        <span ref={commentsEndRef} />
      </S.CommentsSection>
      <CreateComment
        inputRef={inputRef}
        isGuest={biteShareUser?.is_guest || false}
        comment={selectedComment}
        setSelectedComment={setSelectedComment}
      />

      <CustomIntercomLauncher
        marginBottom={100}
        position={(isPreview && shouldRedirectToHome) || (!isAuthenticated && shouldRedirectToHome) ? 'right' : 'left'}
      />
    </S.Container>
  );
};

const S: any = {};

S.EmptyImage = styled.img`
  width: 210px;
  height: auto;
`;

S.EmptyTitle = styled.div`
  margin-top: 26px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.25;
  color: #51688c;
`;

S.EmptySubTitle = styled.div`
  margin-top: 16px;
  opacity: 0.8;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #374b64;
  text-align: center;
`;

S.EmptyState = styled.div<{ hideHeader: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${({ hideHeader }) => (hideHeader ? 160 : 120)}px;

  @media (max-height: 650px) {
    padding-top: 50px;
  }

  @media (max-height: 500px) {
    padding-top: 0;
  }
`;

S.Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

S.CommentsSection = styled.div`
  flex: 1;
  padding: 30px 30px 0 30px;
`;

S.CommentsChunkContainer = styled.div`
  margin-bottom: 19px;
  position: relative;
  z-index: 5;
`;

S.CommentListWrapper = styled.div`
  padding-left: 8px;
  position: relative;
  z-index: -1;
  writing-mode: horizontal-tb;
  ${() => (getIsRtl() ? 'direction: rtl;' : '')};
  ${() => (getIsRtl() ? 'padding-right: 8px;' : 'padding-left: 8px;')};
  ${() => (getIsRtl() ? 'padding-right: 8px;' : 'padding-left: 8px;')};
`;

export default Discussion;
