import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import SuggestionList from './SuggestionList';
import { useDispatch, useSelector } from 'react-redux';
import { knowledgeHistorySelector } from '../../../store/knowledge/knowledge.selectors';
import { fetchHistory } from '../../../store/knowledge/knowledge.slice';
import { ReactComponent as SearchSVG } from '../../../assets/icons/search.svg';
import useKeyboardHeight from '../../../hooks/useKeyboardHeight';
import { useTranslation } from 'react-i18next';
import { APP_WIDTH } from '../../../utils/constants';
import { isMobile } from '../../../utils/utils';
import { getIsRtl as rtl } from '../../../locale/i18n';
import {
  applyButtonCy,
  suggestionCy,
  suggestionModeHistoryCy,
  // suggestionModeSuggestionsCy
} from '../aiPrompt.constants';

type TSuggestionsMode = 'suggestion' | 'history';

const SUGGESTIONS = [
  { id: 0, prompt: 'How to get a service report?' },
  { id: 1, prompt: 'Where I can find a bite?' },
  { id: 2, prompt: 'How to create a playlist?' },
  { id: 3, prompt: 'How to get a bite?' },
  { id: 4, prompt: 'Where I can find a bite?' },
  { id: 5, prompt: 'How to create a playlist?' },
];

interface IProps {
  onApplyClick: () => void;
}

const Suggestions: React.FC<IProps> = ({ onApplyClick }) => {
  const isRtl = rtl();
  const isDevice = isMobile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [mode, setMode] = useState<TSuggestionsMode>('history');
  const history = useSelector(knowledgeHistorySelector);

  const { keyboardHeight } = useKeyboardHeight();

  const handleHistoryClick = useCallback(() => setMode('history'), []);

  useEffect(() => {
    if (!history) {
      dispatch(fetchHistory());
    }
  }, [dispatch, history]);

  return (
    <S.Container data-cy={suggestionCy} isRtl={isRtl} isDevice={isDevice} keyboardHeight={keyboardHeight}>
      <S.Left>
        <S.SuggestionHeader isRtl={isRtl}>
          {/*<S.SuggestionsMode data-cy={suggestionModeSuggestionsCy} isRtl={isRtl} onClick={handleSuggestionClick} isSelected={mode === 'suggestion'}>*/}
          {/*  {t('knowledge.suggestion.suggestion')}*/}
          {/*</S.SuggestionsMode>*/}
          {history?.length > 0 && (
            <>
              {/*<S.Separator>{'|'}</S.Separator>*/}
              <S.SuggestionsMode
                data-cy={suggestionModeHistoryCy}
                isRtl={isRtl}
                onClick={handleHistoryClick}
                isSelected={mode === 'history'}
              >
                {t('knowledge.suggestion.history')}
              </S.SuggestionsMode>
            </>
          )}
        </S.SuggestionHeader>
        <SuggestionList data={mode === 'suggestion' ? SUGGESTIONS : history} key={mode} />
      </S.Left>
      <S.Right>
        <S.ApplyButton data-cy={applyButtonCy} onClick={onApplyClick}>
          <S.SearchSVG />
        </S.ApplyButton>
      </S.Right>
    </S.Container>
  );
};

const S = {
  Left: styled.div`
    width: 80%;
    justify-content: center;
    align-items: center;
    height: 100%;
  `,
  Right: styled.div`
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  SearchSVG: styled(SearchSVG)`
    width: 21px;
    height: 21px;
    fill: ${({ theme }) => theme.colors.white};
  `,
  ApplyButton: styled.button`
    cursor: pointer;
    width: 41px;
    height: 41px;
    background: ${({ theme }) => theme.colors.primaryBlue};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
  `,
  Container: styled.div<{ keyboardHeight: number; isDevice: boolean; isRtl: boolean }>`
    position: fixed;
    display: flex;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: flex-end;
    bottom: ${({ keyboardHeight }) => (keyboardHeight ? `${keyboardHeight}px` : '0')};
    left: auto;
    right: auto;
    width: ${({ isDevice }) => (isDevice ? '100%' : `${APP_WIDTH}px`)};
    margin: 0 auto;
    background: ${({ theme }) => theme.colors.white};
    padding-bottom: 18px;
    z-index: 3;
    transition: bottom 0.2s ease-in-out;
  `,
  SuggestionHeader: styled.div<{ isRtl: boolean }>`
    margin: 0 20px;
    display: flex;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
  `,
  SuggestionsMode: styled.div<{ isSelected: boolean; isRtl: boolean }>`
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${({ isSelected, theme }) => (isSelected ? theme.colors.text : theme.colors.darkGray9)};
    font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
    direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  `,
  Separator: styled.div`
    margin: 0 7px;
  `,
};

export default Suggestions;
