import React, { memo } from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyFeedIcon } from '../../../assets/icons/empty-feed.svg';
import { useTranslation } from 'react-i18next';
import { getIsRtl } from '../../../locale/i18n';

interface IProps {
  onReload: () => void;
}

const EmptyList: React.FC<IProps> = ({ onReload }) => {
  const { t } = useTranslation();
  const isRtl = getIsRtl();
  return (
    <S.Container isRtl={isRtl}>
      <S.IconContainer isRtl={isRtl}>
        <EmptyFeedIcon width={38} height={24} />
      </S.IconContainer>
      <S.TextContainer>
        <S.EmptyListText>
          {t('feed.emptyFeed.emptyFocusedText')}{' '}
          <S.RefreshButton onClick={onReload}>{t('feed.refresh')}</S.RefreshButton>
        </S.EmptyListText>
      </S.TextContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ isRtl: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 56px;
    margin: 0 20px 32px;
    padding: 9px 15px;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.colors.info};
    ${({ isRtl }) => (isRtl ? 'direction: rtl;' : '')}
  `,
  IconContainer: styled.div<{ isRtl: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ isRtl }) => (isRtl ? 'margin-left: 12px;' : 'margin-right: 12px;')}
  `,
  TextContainer: styled.div``,
  EmptyListText: styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    line-height: 130%; /* 19.5px */
  `,
  RefreshButton: styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
    line-height: 130%;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
  `,
};

export default memo(EmptyList);
