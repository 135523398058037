import React, { memo } from 'react';
import styled from 'styled-components';
import { TSize } from './FeedResultItem';

interface IProps {
  size?: TSize;
  showPercents?: boolean;
  progress: number;
}

const ProgressCircle: React.FC<IProps> = ({ size, showPercents, progress }) => {
  return (
    <S.ProgressContainer size={size}>
      <S.ProgressBackground />
      <S.ProgressCircle size={size} progress={progress} />
      {showPercents && <S.ProgressText>{progress}%</S.ProgressText>}
    </S.ProgressContainer>
  );
};

const SIZE_MAP = {
  big: 38,
  medium: 38,
  small: 17,
};

const S = {
  ProgressContainer: styled.div<{ size: TSize }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ size }) => SIZE_MAP[size]}px;
    height: ${({ size }) => SIZE_MAP[size]}px;
    border-radius: 50%;
  `,
  ProgressBackground: styled.div`
    z-index: 1;
    position: absolute;
    width: 88%;
    height: 88%;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.64);
  `,
  ProgressCircle: styled.div<{ progress: number; size: TSize }>`
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(
      #17c868 ${({ progress }) => progress}%,
      rgba(125, 125, 125, 0.6) ${({ progress }) => progress}%
    );
    mask: ${({ size }) =>
      `radial-gradient(circle, transparent ${size === 'small' ? 5 : 14}px, black ${size === 'small' ? 6 : 15}px)`};
  `,
  ProgressText: styled.div`
    z-index: 3;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 11px;
    font-weight: 700;
  `,
};

export default memo(ProgressCircle);
