import { FeedBlockConfig, Key } from './feed.types';

export const FEED_CONFIG: { type: 'FEED_BLOCK' | 'ASK_AI'; key?: Key; isBig?: boolean }[] = [
  {
    type: 'FEED_BLOCK',
    key: 'todo',
    isBig: true,
  },
  {
    type: 'FEED_BLOCK',
    key: 'recommended',
  },
  {
    type: 'ASK_AI',
  },
  // {
  //   type: 'FEED_BLOCK',
  //   key: 'categories',
  // },
  {
    type: 'FEED_BLOCK',
    key: 'mandatory',
  },
  {
    type: 'FEED_BLOCK',
    key: 'allContent',
  },
];

export const FEED_BLOCK_CONFIGS: Record<Key, FeedBlockConfig> = {
  todo: {
    title: 'feed.blockTitles.todo', // path in translations JSON
    lists: [
      {
        filters: {
          isAssigned: true,
          isCompleted: false,
        },
        sort: {
          assignmentDueDate: 1,
        },
      },
    ],
  },
  recommended: {
    title: 'feed.blockTitles.recommended', // path in translations JSON
    lists: [
      {
        filters: {
          isAssigned: false,
          isCompleted: false,
        },
        sort: {
          feedItemId: 1,
        },
      },
      {
        filters: {
          isAssigned: false,
          isCompleted: true,
        },
        sort: {
          feedItemId: 1,
        },
      },
    ],
  },
  // category: {
  //   title: 'feed.blockTitles.recommended', // path in translations JSON
  //   lists: [
  //     {
  //       filters: {
  //         // to think how to dynamically apply category id filter
  //         isAssigned: true,
  //         isCompleted: false,
  //       },
  //       sort: {
  //         assignmentDueDate: 1,
  //       },
  //     },
  //     {
  //       filters: {
  //         isAssigned: false,
  //         isCompleted: false,
  //       },
  //       sort: {
  //         feedItemId: 1,
  //       },
  //     },
  //     {
  //       filters: {
  //         isAssigned: true,
  //         isCompleted: true,
  //       },
  //       sort: {
  //         feedItemId: 1,
  //       },
  //     },
  //     {
  //       filters: {
  //         isAssigned: false,
  //         isCompleted: true,
  //       },
  //       sort: {
  //         feedItemId: 1,
  //       },
  //     },
  //   ],
  // },
  mandatory: {
    title: 'feed.blockTitles.mandatory', // path in translations JSON
    lists: [
      {
        filters: {
          // to think how to dynamically apply category id filter
          isAssigned: true,
          isCompleted: false,
        },
        sort: {
          assignmentDueDate: 1,
        },
      },
      {
        filters: {
          isAssigned: true,
          isCompleted: true,
        },
        sort: {
          feedItemId: 1,
        },
      },
    ],
  },
  allContent: {
    title: 'feed.blockTitles.allContent', // path in translations JSON
    lists: [
      {
        filters: {
          isAssigned: true,
          isCompleted: false,
        },
        sort: {
          assignmentDueDate: 1,
        },
      },
      {
        filters: {
          isAssigned: false,
          isCompleted: false,
        },
        sort: {
          feedItemId: 1,
        },
      },
      {
        filters: {
          isAssigned: true,
          isCompleted: true,
        },
        sort: {
          feedItemId: 1,
        },
      },
      {
        filters: {
          isAssigned: false,
          isCompleted: true,
        },
        sort: {
          feedItemId: 1,
        },
      },
    ],
  },
};
