import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as HamburgerIcon } from '../../../assets/icons/hamburger.svg';
import useIsInsideMsTeams from '../../../hooks/useIsInsideMsTeams';
import { getIsRtl } from '../../../locale/i18n';
import HeaderHomeButton from '../../../components/HeaderHomeButton';
import gtmTrack from '../../../services/googleTagManager/track';
import { HEADER_HEIGHT } from '../../../components/layout/StyledHeader';
import { Loader } from '../../../components/shared';
import useGetOrganizationLogo from '../../../hooks/useGetOrganizationLogo';

interface IProps {
  onHamburgerClick?: () => void;
  onGoHomeClick?: () => void;
  showHomeIcon?: boolean;
  isPreview?: boolean;
  renderRight?: () => React.ReactNode;
}

const Header: FC<IProps> = ({ onHamburgerClick, onGoHomeClick, showHomeIcon, isPreview, renderRight }) => {
  const { isInsideMsTeams } = useIsInsideMsTeams();
  const { logo, isLoadingLogo } = useGetOrganizationLogo();

  const handleHomeButtonClick = useCallback(() => {
    onGoHomeClick();
    gtmTrack('back_to_home_button', {});
  }, [onGoHomeClick]);

  const renderRightComponent = useCallback(() => {
    if (showHomeIcon && !isPreview) {
      return <HeaderHomeButton onClick={handleHomeButtonClick} icon='home' />;
    }

    if (typeof renderRight === 'function') {
      return renderRight();
    }

    return <S.EmptyDiv />;
  }, [handleHomeButtonClick, isPreview, renderRight, showHomeIcon]);

  return (
    <S.Header>
      <S.LeftWrapper>
        {!isInsideMsTeams && !isPreview && typeof onHamburgerClick === 'function' ? (
          <S.Hamburger onClick={onHamburgerClick} id='btnOpenSideMenuWrapper' data-cy='openSideMenu'>
            <S.HamburgerIcon id='btnOpenSideMenu' />
          </S.Hamburger>
        ) : (
          <S.EmptyDiv />
        )}
      </S.LeftWrapper>
      <S.CompanyLogoContainer>
        {isLoadingLogo ? <Loader /> : <S.CompanyLogo src={logo} data-cy='logo' />}
      </S.CompanyLogoContainer>
      <S.RightWrapper>{renderRightComponent()}</S.RightWrapper>
    </S.Header>
  );
};

const LOGO_CONTAINER_HEIGHT = 50;
const S: any = {
  EmptyDiv: styled.div`
    flex: 1;
    width: 41px;
    height: 41px;
  `,
  Header: styled.div`
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => css`
      color: ${theme.colors.text};
    `};
    ${() =>
      getIsRtl() &&
      `
			direction: rtl;
		`};
  `,
  LeftWrapper: styled.div`
    flex: 1;
    display: flex;
    align-items: center;
  `,
  RightWrapper: styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `,
  Hamburger: styled.div`
    width: 41px;
    height: 41px;
    border-radius: 50%;
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    cursor: pointer;
  `,
  HamburgerIcon: styled(HamburgerIcon)`
    width: 18px;
    ${({ theme }) => css`
      fill: ${theme.colors.primaryBlue};
    `}
  `,
  Username: styled.p`
    flex: 1;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    text-align: Center;
  `,
  CompanyLogoContainer: styled.div`
    height: ${LOGO_CONTAINER_HEIGHT}px;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  CompanyLogo: styled.img`
    max-width: 100%;
    height: ${LOGO_CONTAINER_HEIGHT}px;
  `,
};

export default Header;
