import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { getRedirectUri } from '../utils/redirection';
import { authSelector, isAuthenticatedSelector } from '../store/auth/auth.selectors';
import { orgSelector } from '../store/organization/organization.selectors';
import { socialShareSelector } from '../store/socialShare/socialShare.slice';
import { getFullName } from '../utils/auth';

function Intercom() {
  const { title } = useSelector(socialShareSelector);
  const currentUser = useSelector(authSelector);
  const org = useSelector(orgSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const { boot, shutdown, update } = useIntercom();

  useEffect(() => {
    boot();

    return () => {
      shutdown();
    };
  }, [boot, shutdown]);

  useEffect(() => {
    update({
      hideDefaultLauncher: !window?.location?.pathname.includes('auth'),
    });
  }, [update, isAuthenticated]);

  useEffect(() => {
    if (currentUser) {
      const name = getFullName(currentUser);
      update({
        name: `${name} (Frontliner)`,
      });
    } else {
      setTimeout(() => {
        const redirectURL = getRedirectUri();
        update({
          name: 'Guest (Frontliner)',
          customAttributes: {
            org_name: org.name,
            org_id: org.id,
            title,
            url_trying_to_access: redirectURL,
          },
        });
      }, 2500); // allow time to process last call to Intercom API
    }
  }, [currentUser, org.id, org.name, title, update]);

  return null;
}

export default Intercom;
