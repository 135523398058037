import BitesApi from '..';
import { FeedDTRequest, FeedResultItem } from '../../feed/feed.types';
import { DTResult } from '../../types';

export const getFeed = (dtRequest: FeedDTRequest) =>
  BitesApi.post<
    DTResult<{
      feedItem: FeedResultItem;
    }>
  >(`/content/organization/${dtRequest.filters.orgId}/feed/search`, dtRequest);
