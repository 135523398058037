import { RootState } from '..';
import { Features } from '../../utils/featureFlag/featureFlag.types';
import { selectedBiteSelector } from '../bite/bite.selectors';
import { organizationSelector as orgSelector } from '../organization/organization.slice';

export const organizationSelector = (state: RootState) => state.profile.organization;
export const organizationLoadingSelector = (state: RootState) => state.profile.isLoading;

export const organizationHasFeatureFlagSelector =
  ({ feature, orgId }: { feature: Features; orgId: number }) =>
  (state: RootState) => {
    return state.profile.featureFlagsByOrgId[orgId]?.[feature] || false;
  };

export const featureFlagsByOrgIdSelector = (state: RootState) => state.profile.featureFlagsByOrgId;

export const isBiteRequiresCorrectAnswerSelector = (state: RootState) => {
  const { id: orgId } = orgSelector(state);
  const { selectedBite } = selectedBiteSelector(state);

  const orgRequireCorrectAnswer = organizationHasFeatureFlagSelector({
    feature: Features.REQUIRE_CORRECT_ANSWER,
    orgId,
  })(state);

  return orgRequireCorrectAnswer || selectedBite.require_correct_answer;
};
