import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  knowledgeErrorSelector,
  knowledgeIsLoadingSelector,
  knowledgeRelatedContentSelector,
  knowledgeTagsSearchDataSelector,
} from '../../../store/knowledge/knowledge.selectors';
import AnswerLoader from './AnswerLoader';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo.svg';
import { useTranslation } from 'react-i18next';
import { getIsRtl as rtl } from '../../../locale/i18n';
import { log } from '../../../store/tracking/tracking.slice';
import EmptyAnswer from './EmptyAnswer';
import {
  betaPlateCy,
  failureAnswerCy,
  showMoreButtonCy,
  successAnswerCollapsedCy,
  successAnswerExpandedCy,
} from '../aiPrompt.constants';

const ANSWER_LINE_HEIGHT = 19;
const COLLAPSED_ANSWER_HEIGHT = ANSWER_LINE_HEIGHT * 3;
const ANSWER_SIGN_HEIGHT = 25;

const Answer = () => {
  const dispatch = useDispatch();
  const isRtl = rtl();
  const { t } = useTranslation();
  const isLoading = useSelector(knowledgeIsLoadingSelector);
  const isError = useSelector(knowledgeErrorSelector);

  const { searchRelated, answerRelated, answer } = useSelector(knowledgeRelatedContentSelector);
  const searchData = useSelector(knowledgeTagsSearchDataSelector);

  const showEmptyState = !answer && !isLoading && (!!searchRelated || !!answerRelated || !!searchData);

  const answerTextRef = useRef<HTMLDivElement>(null);

  const [displayedAnswer, setDisplayedAnswer] = useState('');
  const [isCollapsed, setIsCollapsed] = useState(false);

  const currentAnswer = useMemo(() => {
    if (isError) {
      return t('common.somethingWentWrong');
    }

    return answer;
  }, [answer, isError, t]);

  useEffect(() => {
    if (!answer || !answerTextRef.current || showEmptyState || isError) {
      return;
    }

    const interval = setInterval(() => {
      if (answerTextRef.current && answerTextRef.current.scrollHeight > COLLAPSED_ANSWER_HEIGHT) {
        setIsCollapsed(true);
        setDisplayedAnswer(answer);
        clearInterval(interval);
        return;
      }

      if (displayedAnswer.length === answer.length) {
        clearInterval(interval);
        return;
      }

      setDisplayedAnswer(answer.slice(0, displayedAnswer.length + 1));
    }, 20);

    return () => clearInterval(interval);
  }, [answer, displayedAnswer.length, isError, showEmptyState]);

  const handleShowMore = useCallback(() => {
    if (!isCollapsed) {
      return;
    }
    dispatch(log({ event: 'Answer.handleShowMore' }));
    setIsCollapsed(false);
  }, [dispatch, isCollapsed]);

  useEffect(() => {
    setIsCollapsed(false);
    setDisplayedAnswer('');
  }, [answer]);

  const answerHeight = useMemo(() => {
    if (!answerTextRef.current) {
      return 0;
    }

    const measureDiv = document.createElement('div');
    measureDiv.style.visibility = 'hidden';
    measureDiv.style.width = '100%';
    measureDiv.textContent = currentAnswer || '';

    answerTextRef.current.appendChild(measureDiv);
    const height = measureDiv.scrollHeight;
    measureDiv.remove();

    return height;
  }, [currentAnswer]);

  const containerHeight = useMemo(() => {
    if (answerHeight > COLLAPSED_ANSWER_HEIGHT) {
      return COLLAPSED_ANSWER_HEIGHT + ANSWER_SIGN_HEIGHT;
    }

    return answerHeight + ANSWER_SIGN_HEIGHT;
  }, [answerHeight]);

  const renderAnswer = useMemo(() => {
    if (isLoading && !answer) {
      return <AnswerLoader />;
    }

    if (isError) {
      return <S.Error data-cy={failureAnswerCy}>{currentAnswer}</S.Error>;
    }

    if (showEmptyState) {
      return <EmptyAnswer />;
    }

    return displayedAnswer;
  }, [answer, currentAnswer, displayedAnswer, isError, isLoading, showEmptyState]);

  const answerDataCy = useMemo(() => {
    if (currentAnswer && !isError && !showEmptyState) {
      if (!isCollapsed) {
        return successAnswerExpandedCy;
      }

      return successAnswerCollapsedCy;
    }
    return undefined;
  }, [currentAnswer, isCollapsed, isError, showEmptyState]);

  if (!answer && !isLoading && !showEmptyState && !isError) {
    return null;
  }

  return (
    <S.Container showEmptyState={showEmptyState} minHeight={containerHeight} isRtl={isRtl}>
      <S.LogoContainer>
        <S.LogoIcon />
      </S.LogoContainer>
      <S.AnswerContainer>
        <S.Answer
          data-cy={answerDataCy}
          onClick={handleShowMore}
          showEmptyState={showEmptyState}
          height={isCollapsed && answerHeight > COLLAPSED_ANSWER_HEIGHT ? COLLAPSED_ANSWER_HEIGHT : answerHeight}
          isRtl={isRtl}
          ref={answerTextRef}
          isCollapsed={isCollapsed}
        >
          {renderAnswer}
        </S.Answer>
        {isCollapsed && (
          <S.ShowMoreButton data-cy={showMoreButtonCy} isRtl={isRtl} onClick={handleShowMore}>
            {t('knowledge.answer.readMore')}
          </S.ShowMoreButton>
        )}
        {!isCollapsed && answer?.length === displayedAnswer.length && (
          <S.BetaPlate data-cy={betaPlateCy} isRtl={isRtl}>
            {t('knowledge.answer.thisIsA')}
            <S.BetaText>{t('knowledge.answer.beta')}</S.BetaText>
            {t('knowledge.answer.considerCheckingInformation')}
          </S.BetaPlate>
        )}
      </S.AnswerContainer>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ isRtl: boolean; minHeight: number; showEmptyState: boolean }>`
    position: relative;
    width: 100%;
    display: flex;
    min-height: ${({ minHeight }) => minHeight}px;
    height: auto;
    align-items: flex-start;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    margin-top: 22px;
    padding: 0 16px;
  `,
  Answer: styled.div<{ height: number; isCollapsed?: boolean; isRtl: boolean; showEmptyState: boolean }>`
    font-size: 15px;
    line-height: ${ANSWER_LINE_HEIGHT}px;
    color: ${({ theme }) => theme.colors.text};
    width: 100%;
    max-height: ${({ showEmptyState, height }) => (!showEmptyState && height ? `${height}px` : 'auto')};
    display: flex;
    vertical-align: top;
    flex-direction: column;
    direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
    overflow: hidden;
    ${({ isCollapsed }) => (isCollapsed ? 'cursor: pointer;' : '')}
  `,
  LogoIcon: styled(LogoIcon)`
    width: 20px;
    height: 20px;
  `,
  Error: styled.span`
    color: ${({ theme }) => theme.colors.failRed};
  `,
  ShowMoreButton: styled.div<{ isRtl: boolean }>`
    font-size: 15px;
    color: ${({ theme }) => theme.colors.blue3};
    cursor: pointer;
    text-decoration: underline;
    align-self: ${({ isRtl }) => (isRtl ? 'flex-end' : 'flex-start')};
  `,
  AnswerContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 15px;
  `,
  BetaPlate: styled.div<{ isRtl: boolean }>`
    display: flex;
    margin-top: 10px;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.gray19};
    align-self: ${({ isRtl }) => (isRtl ? 'flex-end' : 'flex-start')};
    direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  `,
  BetaText: styled.span`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-size: 12px;
    font-weight: 700;
    margin: 0 5px;
  `,
  LogoContainer: styled.div`
    margin-top: -4px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    border-radius: 50%;
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
  `,
};

export default Answer;
