import React, { FC, ReactChildren } from 'react';
import styled, { css } from 'styled-components';

import { getIsRtl } from '../../../locale/i18n';
import Loader from '../Loader';

export interface IProps extends React.HTMLProps<HTMLButtonElement> {
  children?: ReactChildren | string | any;
  height?: number;
  inline?: boolean;
  icon?: any;
  inverted?: boolean;
  isLoading?: boolean;
  isTransparent?: boolean;
  className?: string;
  id?: string;
  isBigText?: boolean;
  isMediumText?: boolean;
  paddingStart?: number;
}

const Button: FC<IProps> = ({
  children,
  isLoading,
  isTransparent,
  icon: Icon,
  id,
  isBigText,
  isMediumText,
  paddingStart,
  height = 44,
  inline = false,
  ...rest
}) => {
  return (
    <S.Button
      isTransparent={isTransparent}
      isLoading={isLoading}
      inline={inline}
      height={height}
      hasIcon={!!Icon}
      {...rest}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <S.Text
            id={`${id}Text`}
            isBigText={isBigText}
            isMediumText={isMediumText}
            paddingStart={paddingStart}
            height={height}
            hasIcon={!!Icon}
          >
            {children}
          </S.Text>
          {Icon && (
            <S.IconWrap height={height}>
              <Icon />
            </S.IconWrap>
          )}
        </>
      )}
    </S.Button>
  );
};

const S: any = {};

const invertedStyle = css`
  ${({ theme }) => css`
    color: ${theme.colors.primaryBlue};
    background-color: ${theme.colors.white};
  `}
`;
S.Button = styled.button<{
  disabled?: boolean;
  inverted?: boolean;
  isTransparent: boolean;
  height: number;
  hasIcon: boolean;
  inline: boolean;
  isLoading: boolean;
}>`
  position: relative;

  ${({ inline }) => css`
    ${!inline ? 'width: 100%' : ''}
  `};
  height: ${({ height }) => css`
    ${height}px;
  `}
  line-height: ${({ height }) => css`
    ${height}px;
  `};
  border-radius: 29px;
  padding: ${({ hasIcon, height }) => css`
    ${height / 4}px ${hasIcon && !getIsRtl() ? height / 4 : height}px ${height / 4}px ${hasIcon && getIsRtl()
      ? height / 4
      : height}px;
  `};

  display: ${({ inline }) => css`
    ${inline ? 'inline-flex' : 'flex'}
  `};
  align-items: center;
  justify-content: ${getIsRtl() ? 'flex-start' : 'flex-end'};

  ${({ theme, disabled, inverted, isTransparent, isLoading }) => css`
    color: ${isTransparent ? theme.colors.lightGray6 : theme.colors.white};
    border: ${isTransparent ? `1px solid ${theme.colors.lightGray5}` : 'none'};
    background-color: ${disabled && !isLoading
      ? theme.colors.darkGray3
      : isTransparent
      ? 'transparent'
      : theme.colors.primaryBlue};
    ${inverted && invertedStyle};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}
`;
S.Text = styled.span<{
  isBigText?: boolean;
  isMediumText?: boolean;
  paddingStart?: number;
  height: number;
  hasIcon: boolean;
}>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  letter-spacing: 1.2px;
  white-space: nowrap;
  font-size: 15px;
`;

S.IconWrap = styled.div<{
  height: number;
}>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ height }) => css`
    margin-right: ${getIsRtl() ? `${(height * 3) / 8}px` : 0};
    margin-left: ${!getIsRtl() ? `${(height * 3) / 8}px` : 0};
  `}
  width: ${({ height }) => css`
    ${height / 2}px;
  `}
  height: ${({ height }) => css`
    ${height / 2}px;
  `}
`;

export default Button;
