import React, { useMemo, useState, useEffect, useCallback, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addMultipleChoicesAnswer, addOpenEndedAnswer } from '../../../store/biteShareUser/biteShareUser.actions';
import useMoveToNextBiteSection from '../../../hooks/useMoveToNextBiteSection';
import { biteShareUserSelector } from '../../../store/biteShareUser/biteShareUser.selectors';
import { selectedBiteSelector } from '../../../store/bite/bite.selectors';
import { IAnswer } from '../../../types/bite';
import Question from '../../../components/question/Question';
import { gtmClick } from '../../../services/googleTagManager';
import gtmTrack from '../../../services/googleTagManager/track';
import useQueryParams from '../../../hooks/useQueryParams';
import { log } from '../../../store/tracking/tracking.slice';

interface IProps {
  question: {
    id: number;
    text: string;
    type: string;
    choices: any[];
    is_multi_choice?: boolean;
  };
  choices: any[];
  questionTranslation: string[];
  choicesTranslations: string[];
  media?: {
    id?: number;
    auto_play_muted: boolean;
    bite_section: number;
    created_at: string;
    file_type: 'image' | 'video' | 'youtube';
    image_url: string | null;
    media_url: string;
    video_duration: number;
    video_ends_at: number;
    video_start_at: number;
    section: string | null;
  };
  coverUrl: string;
  isModalOpen: boolean;
  setIsModalOpen: any;
  hasSummaryOrDiscussion: boolean;
}

const BiteQuestion: FC<IProps> = ({
  question,
  choices,
  questionTranslation,
  choicesTranslations,
  media,
  coverUrl,
  isModalOpen,
  setIsModalOpen,
  hasSummaryOrDiscussion,
}) => {
  const dispatch = useDispatch();

  const { queryParams } = useQueryParams();
  const playlistId = queryParams?.playlistId;

  const { selectedBite } = useSelector(selectedBiteSelector);
  const biteShareUser = useSelector(biteShareUserSelector);

  const [imageForOpenEnded, setImageForOpenEnded] = useState(null);
  const [openEndedText, setOpenEndedText] = useState('');
  const [userChoicesIds, setUserChoicesIds] = useState<number[]>(() =>
    selectedBite?.bite_share_user?.choices?.map((choice) => choice.choice?.id),
  );

  const isAnswered = useMemo(() => {
    return selectedBite?.bite_share_user?.choices?.length > 0 || biteShareUser?.choices?.length > 0;
  }, [biteShareUser?.choices?.length, selectedBite?.bite_share_user?.choices?.length]);

  const { proceedToNextSection, proceedToNextSectionWithDelay, nextSectionPath, isLoading, setIsLoading } =
    useMoveToNextBiteSection({
      sectionType: 'question',
    });

  const isMultiChoiceSurvey = question.is_multi_choice && question.type === 'survey';

  const isUserCorrect = useMemo(() => {
    if (question.type !== 'multiple choices') {
      return undefined;
    }

    const correctChoice = question.choices?.find((choice) => choice.is_correct);
    // To ensure that user's choice is marked as incorrect when we do not load the "correct ones" from the server, but the answer is incorrect
    // we return correctChoice === false and not null. This allows us to render in interaction in the UI
    const newIsUserCorrect =
      correctChoice && userChoicesIds.length > 0 ? correctChoice.id === userChoicesIds[0] : isAnswered ? false : null;

    return newIsUserCorrect;
  }, [isAnswered, question.choices, question.type, userChoicesIds]);

  const submitChoices = useCallback(
    (choiceIds: number[], callback?: () => void) => {
      setIsLoading(true);

      const answer: IAnswer = {
        choices_ids: choiceIds,
        bite_share_user: selectedBite.bite_share_user.id,
        answer: '',
        question: question.id,
      };
      const errorCallback = () => {
        setIsLoading(false);
      };
      dispatch(addMultipleChoicesAnswer(answer, callback, errorCallback));
    },
    [dispatch, question.id, selectedBite.bite_share_user.id, setIsLoading],
  );

  const handleChoiceSelection = useCallback(
    (choiceId: number) => {
      gtmClick('Question_choice');

      if (isMultiChoiceSurvey) {
        const newCheckedChoices = userChoicesIds.includes(choiceId)
          ? userChoicesIds.filter((choice) => choice !== choiceId)
          : [...userChoicesIds, choiceId];

        setUserChoicesIds(newCheckedChoices);
        return;
      }

      setUserChoicesIds([choiceId]);
      submitChoices([choiceId], proceedToNextSectionWithDelay);
    },
    [userChoicesIds, setUserChoicesIds, isMultiChoiceSurvey, submitChoices, proceedToNextSectionWithDelay],
  );

  const handleOpenEndedTextChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setOpenEndedText(event.target.value);
  }, []);

  const handleSubmitOpenEnded = useCallback(() => {
    if (isAnswered) {
      proceedToNextSection();
      return;
    }

    const answer: IAnswer = {
      choices_ids: null,
      bite_share_user: selectedBite.bite_share_user.id,
      answer: openEndedText,
      question: question.id,
      image: imageForOpenEnded,
    };
    dispatch(addOpenEndedAnswer(answer));

    proceedToNextSectionWithDelay();
  }, [
    dispatch,
    imageForOpenEnded,
    isAnswered,
    openEndedText,
    proceedToNextSection,
    proceedToNextSectionWithDelay,
    question?.id,
    selectedBite?.bite_share_user?.id,
  ]);

  const handlePlay = useCallback(
    (evt) => {
      evt.preventDefault();
      setIsModalOpen(true);
    },
    [setIsModalOpen],
  );

  // only multi choice or survey
  const handleContinue = useCallback(() => {
    gtmClick('Question_continue');

    if (isMultiChoiceSurvey && !isAnswered) {
      submitChoices(userChoicesIds);
    }

    proceedToNextSection();
  }, [isMultiChoiceSurvey, isAnswered, proceedToNextSection, submitChoices, userChoicesIds]);

  useEffect(() => {
    gtmTrack('bite_question_section', {
      bite_id: selectedBite?.bite,
      bite_share_id: selectedBite?.id,
      content_org_id: selectedBite?.orgid,
      sharing_mode: selectedBite?.sharing_mode,
      playlist_id: playlistId,
    });

    return () => {
      dispatch(
        log({
          event: 'BiteQuestion: unmount',
          data: {
            biteShareId: selectedBite?.id,
          },
        }),
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistId, selectedBite]);

  useEffect(() => {
    if (question.type === 'open ended' && isAnswered) {
      setOpenEndedText(biteShareUser.choices[0].text);
      setImageForOpenEnded(biteShareUser.choices[0].media?.image_url);
    }
  }, [question, biteShareUser, isAnswered]);

  return (
    <Question
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      coverUrl={coverUrl}
      onPlay={handlePlay}
      media={media}
      question={question}
      questionTranslation={questionTranslation}
      choicesTranslations={choicesTranslations}
      openEndedText={openEndedText}
      onOpenEndedTextChange={handleOpenEndedTextChange}
      imageForOpenEnded={imageForOpenEnded}
      onImageForOpenEndedChange={setImageForOpenEnded}
      onSubmitOpenEnded={handleSubmitOpenEnded}
      isUserCorrect={isUserCorrect}
      choices={choices}
      onChoiceClick={handleChoiceSelection}
      userChoicesIds={userChoicesIds}
      nextSectionPath={nextSectionPath}
      onContinue={handleContinue}
      hasSummaryOrDiscussion={hasSummaryOrDiscussion}
      isMultiChoiceSurvey={isMultiChoiceSurvey}
      isAnswered={isAnswered}
      isInPlaylist={!!playlistId}
      isDelaying={isLoading}
    />
  );
};

export default BiteQuestion;
