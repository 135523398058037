import React, { CSSProperties, FC } from 'react';
import styled from 'styled-components';
import noop from 'lodash/noop';

import BackDrop from './BackDrop';

import useIsInsideMsTeams from '../../../hooks/useIsInsideMsTeams';

interface IProps {
  open: boolean;
  onClose: () => void;
  closeOnBlur?: boolean;
  style?: CSSProperties;
  withoutBackground?: boolean;
}

export const CustomPopup: FC<IProps> = ({ open, onClose, children, closeOnBlur, style, withoutBackground }) => {
  const { isInsideMsTeams } = useIsInsideMsTeams();
  return (
    <S.PopupContainer isInsideMsTeams={isInsideMsTeams} className={open ? 'show' : ''} style={style}>
      <S.Popup withoutBackground={withoutBackground} isInsideMsTeams={isInsideMsTeams} className={open ? 'show' : ''}>
        {children}
      </S.Popup>
      <BackDrop show={open} closeModal={closeOnBlur ? onClose : noop} />
    </S.PopupContainer>
  );
};

const PopupContainer = styled.div<{
  isInsideMsTeams: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  visibility: hidden;
  ${({ isInsideMsTeams }) =>
    isInsideMsTeams
      ? `
				height: calc(100% - 30px);
				width: calc(100% - 60px);
			`
      : `
				width: 100%;
				height: 100%;
			`}
  &.show {
    visibility: visible;
  }
`;

const Popup = styled.div<{
  isInsideMsTeams: boolean;
  withoutBackground: boolean;
}>`
  position: absolute;
  background-color: white;
  width: 98%;
  height: 98%;
  padding: 0;
  top: 15px;
  box-sizing: border-box;
  border-radius: 20px;
  overflow: auto;
  max-height: 100dvh;
  visibility: hidden;
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.3s ease;
  z-index: 50;
  ${({ isInsideMsTeams }) =>
    isInsideMsTeams
      ? `
			width: calc(100% - 60px);
			left: 30px;
		`
      : `
			width: 95%;
		`}
  &.show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  ${({ withoutBackground }) => (withoutBackground ? 'background-color: transparent' : null)};
`;

const CloseBtn = styled.div`
  cursor: pointer;
  text-align: right;
`;

const S = { PopupContainer, Popup, CloseBtn };
