import { combineReducers } from 'redux';

import authReducer from './auth/auth.reducer';
import searchReducer from './search/search.reducer';
import biteReducer from './bite/bite.reducer';
import profileReducer from './profile/profile.reducer';
import playlistReducer from './playlist/playlist.reducer';
import biteShareUserReducer from './biteShareUser/biteShareUser.reducer';
import organizationReducer from './organization/organization.slice';
import socialShareReducer from './socialShare/socialShare.slice';
import quizReducer from './quiz/quiz.slice';
import appActivityReducer from './appActivity/appActivity.slice';
import authFormSlice from './authForm/authForm.slice';
import geofenceReducer from './geofence/geofence.slice';
import knowledgeReducer from './knowledge/knowledge.slice';
import feedSlice from './feed/feed.slice';

const rootReducer = combineReducers({
  authReducer,
  bite: biteReducer,
  playlistReducer,
  biteShareUserReducer: biteShareUserReducer,
  profile: profileReducer,
  search: searchReducer,
  organization: organizationReducer,
  socialShare: socialShareReducer,
  quiz: quizReducer,
  knowledge: knowledgeReducer,
  appActivity: appActivityReducer,
  authForm: authFormSlice,
  geofence: geofenceReducer,
  feed: feedSlice,
});

export default rootReducer;
