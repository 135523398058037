import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as Sentry from '@sentry/react';

import rootSaga from './rootSaga';
import rootReducer from './rootReducer';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import innerLocalStorage from '../utils/localStorage';

const persistConfig = {
  key: 'root-1.0.7',
  whitelist: ['organization'],
  storage,
  stateReconciler: autoMergeLevel2, // shallow merge the slices fields
};

// clear old persisted stores
innerLocalStorage.removeItem('persist:root1');
innerLocalStorage.removeItem('persist:root-1.0.5');
innerLocalStorage.removeItem('persist:root-1.0.6');

export type TStoreType = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer<TStoreType>(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});

const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(sagaMiddleware), sentryReduxEnhancer));

export const persistor = persistStore(store);

if (module.hot) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(persistReducer<TStoreType>(persistConfig, newRootReducer));
  });
}

export default store;

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>; //TODO: declare

// @ts-ignore
// expose for cypress E2E tests
window.store = store;
