import React from 'react';
import styled, { keyframes } from 'styled-components';
import { TSize } from './FeedResultItem';

const SIZE_MAP = {
  coverWidth: {
    big: 219,
    medium: 170,
    small: 130,
  },
  coverHeight: {
    big: 219 / 0.7,
    medium: 170 / 0.7,
    small: 130 / 0.7,
  },
  textWidth: {
    big: 156,
    medium: 121,
    small: 93,
  },
};

export const FeedBlockItemSkeleton = ({ size, withoutMargin }: { size: TSize; withoutMargin?: boolean }) => {
  return (
    <S.FeedResultItemContainer withoutMargin={withoutMargin} size={size}>
      <S.FeedResultItemCover size={size}>
        <Skeleton width={SIZE_MAP.coverWidth[size]} height={SIZE_MAP.coverHeight[size]} />
      </S.FeedResultItemCover>
      <S.TextsContainer>
        <Skeleton width={SIZE_MAP.textWidth[size]} height={18} />
      </S.TextsContainer>
    </S.FeedResultItemContainer>
  );
};

export const FeedBlockSkeleton: React.FC<{ size?: TSize }> = ({ size = 'small' }) => {
  return (
    <>
      <S.FeedBlockHeader>
        <Skeleton width={150} height={20} />
      </S.FeedBlockHeader>
      <S.FeedBlockList>
        <FeedBlockItemSkeleton size={size} />
        <FeedBlockItemSkeleton size={size} />
        <FeedBlockItemSkeleton size={size} />
        <FeedBlockItemSkeleton size={size} />
      </S.FeedBlockList>
    </>
  );
};

export const AiSuggestionBlockSkeleton: React.FC = () => {
  return (
    <>
      <S.FeedBlockHeader>
        <Skeleton width={150} height={20} />
      </S.FeedBlockHeader>
      <S.SuggestionList>
        <S.SuggestionItem>
          <Skeleton width={150} height={52} />
        </S.SuggestionItem>
        <S.SuggestionItem>
          <Skeleton width={150} height={52} />
        </S.SuggestionItem>
        <S.SuggestionItem>
          <Skeleton width={150} height={52} />
        </S.SuggestionItem>
      </S.SuggestionList>
    </>
  );
};

const pulse = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

export const Skeleton = styled.div<{ width?: number; height?: number }>`
  animation: ${pulse} 1.5s infinite ease-in-out;
  background: #e0e0e0;
  border-radius: 4px;
  ${({ width, height }) => `width: ${width}px; height: ${height}px;`}
`;

const S = {
  FeedBlockHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  `,
  FeedBlockList: styled.div`
    display: flex;
    flex-direction: row;
  `,
  FeedResultItemContainer: styled.div<{ size: TSize; withoutMargin?: boolean }>`
    width: ${({ size }) => SIZE_MAP.coverWidth[size]}px;
    cursor: pointer;
    position: relative;
    margin: ${({ withoutMargin }) => (withoutMargin ? 0 : '0 5px')};
    scroll-snap-align: start;
  `,
  FeedResultItemCover: styled.div<{ size: TSize }>`
    width: ${({ size }) => SIZE_MAP.coverWidth[size]}px;
    aspect-ratio: 0.7;
    margin-bottom: 10px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    position: relative;
    overflow: hidden;
  `,
  TextsContainer: styled.div``,
  SuggestionList: styled.div`
    display: flex;
    position: relative;
    height: 52px;
    margin: 12px 0;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    scrollbar-width: none;
    padding-right: 20px;
  `,
  SuggestionItem: styled.div`
    height: 52px;
    width: 150px;
    border-radius: 7px;
    margin-left: 7px;
  `,
};
