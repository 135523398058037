import { useEffect } from 'react';

const useInstallClassToHtml = ({ className }) => {
  useEffect(() => {
    const html = document.documentElement;
    html.classList.add(className);

    return () => {
      html.classList.remove(className);
    };
  }, [className]);
};

export default useInstallClassToHtml;
