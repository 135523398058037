import React from 'react';
import { isMobile } from '../../utils/utils';

import Div100vh from 'react-div-100vh';

const Div100vhDeviceOnly: React.FC = ({ children }) => {
  const isDevice = isMobile();

  if (isDevice) {
    return <Div100vh>{children}</Div100vh>;
  }

  return <>{children}</>;
};

export default Div100vhDeviceOnly;
