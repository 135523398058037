import { useMemo } from 'react';
import { DTDataWithState } from '../store/slice.helpers';

export const useDTListCombinedHasMore = <T>(dtList: DTDataWithState<T>[]) => {
  const hasMore = useMemo(() => {
    return dtList?.some(({ data, totalPages, page }) => {
      return data === null || totalPages === null || page === null || page + 1 < totalPages;
    });
  }, [dtList]);

  return hasMore;
};
