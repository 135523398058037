export type DataWithState<T> = {
  data: T;
  isLoading: boolean;
  error: any;
};

export type DTDataWithState<T> = {
  data: T[];
  page: number | null;
  totalPages: number | null;
  count: number | null;
  isLoading: boolean;
  error: any;
};

export const getDataWithStateValue = () => {
  return JSON.parse(
    JSON.stringify({
      data: null,
      isLoading: false,
      error: null,
    }),
  );
};
export const getDTDataWithStateValue = () => {
  return JSON.parse(
    JSON.stringify({
      data: null,
      page: null,
      totalPages: null,
      count: null,
      isLoading: false,
      error: null,
    }),
  );
};
