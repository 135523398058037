import { useCallback, useMemo, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import useCustomHistory from './useCustomHistory';

import { enableVideoPlaying } from '../store/bite/bite.actions';
import { biteSectionTypesSelector, selectedBiteSelector } from '../store/bite/bite.selectors';
import useGoToNext from '../screens/viewBite/GoNextBite/hooks/useGoToNext';

interface IParams {
  sectionType: string; //limit to the 4 types of sections
}

export default ({ sectionType }: IParams) => {
  const dispatch = useDispatch();

  const { handleNext } = useGoToNext();

  const biteSectionTypes = useSelector(biteSectionTypesSelector);
  const setTimeoutRef = useRef(null);
  const {
    selectedBite: { id: biteShareId },
  } = useSelector(selectedBiteSelector);

  const { historyPushWithPersistedQueryParams } = useCustomHistory();

  const [isLoading, setIsLoading] = useState(false);

  const nextSectionPath = useMemo(() => {
    const currentPathIdx = biteSectionTypes.findIndex((currSectionType) => currSectionType === sectionType);

    return biteSectionTypes[currentPathIdx + 1];
  }, [biteSectionTypes, sectionType]);

  const handleMoveNext = useCallback(() => {
    if (nextSectionPath) {
      historyPushWithPersistedQueryParams(`/bites/${biteShareId}/${nextSectionPath}`);
      return;
    }

    handleNext();
  }, [biteShareId, handleNext, historyPushWithPersistedQueryParams, nextSectionPath]);

  const proceedToNextSection = useCallback(async () => {
    clearTimeout(setTimeoutRef.current);
    setIsLoading(false);

    dispatch(enableVideoPlaying());
    if (!window.location.pathname.includes(nextSectionPath)) {
      handleMoveNext();
    }
  }, [dispatch, handleMoveNext, nextSectionPath]);

  const proceedToNextSectionWithDelay = useCallback(
    (isShortDelay?: boolean) => {
      setIsLoading(true);
      setTimeoutRef.current = setTimeout(
        () => {
          proceedToNextSection();
        },
        isShortDelay ? 375 : 2500,
      );
    },
    [proceedToNextSection],
  );

  return {
    proceedToNextSectionWithDelay,
    proceedToNextSection,
    nextSectionPath,
    isLoading,
    setIsLoading,
  };
};
