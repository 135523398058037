import { Key } from '../../../store/feed/feed.types';
import React, { memo, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { keyDataSelector } from '../../../store/feed/feed.selectors';
import { useDTListCombinedData } from '../../../hooks/useDTListCombinedData';
import { useDTListCombinedHasMore } from '../../../hooks/useDTListCombinedHasMore';
import { useDTListCombinedLoading } from '../../../hooks/useDTListCombinedLoading';
import { getIsRtl } from '../../../locale/i18n';
import { getFeed } from '../../../store/feed/feed.slice';
import { FeedBlockSkeleton } from './Skeleton';
import { FEED_BLOCK_CONFIGS } from '../../../store/feed/feed.constants';
import FeedResultItem from './FeedResultItem';
import styled from 'styled-components';
import { authSelector } from '../../../store/auth/auth.selectors';
import { useDTListCombinedError } from '../../../hooks/useDTListCombinedError';
import SnapCarousel from './Carousel';

interface IFeedBlockProps {
  type: Key;
  isBig?: boolean;
}

const FeedBlock: React.FC<IFeedBlockProps> = ({ type, isBig }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const feedBlockData = useSelector(keyDataSelector(type));
  const { first_name: firstName } = useSelector(authSelector);
  const data = useDTListCombinedData(feedBlockData);
  const hasMore = useDTListCombinedHasMore(feedBlockData);
  const isLoading = useDTListCombinedLoading(feedBlockData);
  const isInitialLoading = feedBlockData[0].isLoading && feedBlockData[0].data === null;
  const isError = useDTListCombinedError(feedBlockData);

  const isRtl = getIsRtl();

  useEffect(() => {
    if (!data.length && !isInitialLoading && !isError) {
      dispatch(getFeed({ key: type }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isInitialLoading, type, isError]);

  const handleEndReached = useCallback(() => {
    if (!hasMore || isLoading) {
      return;
    }

    dispatch(getFeed({ key: type }));
  }, [dispatch, hasMore, isLoading, type]);

  const handleReload = useCallback(() => {
    dispatch(getFeed({ key: type }));
  }, [dispatch, type]);

  const renderItem = useCallback(
    (index: number) => {
      const item = data[index];

      return <FeedResultItem key={item.id} feedResultItem={item} size={isBig ? 'big' : 'small'} />;
    },
    [data, isBig],
  );

  if (!isInitialLoading && !data?.length && !isError && type !== 'todo') {
    return null;
  }

  return (
    <S.FeedBlock>
      {isInitialLoading ? (
        <FeedBlockSkeleton size={isBig ? 'big' : 'small'} />
      ) : data?.length || isError ? (
        <>
          <S.FeedBlockHeader isRtl={isRtl}>
            <S.FeedBlockTitle isRtl={isRtl}>{t(FEED_BLOCK_CONFIGS[type].title, { firstName })}</S.FeedBlockTitle>
            <S.SeeAllButton to={`/feed/${type}`}>{t('feed.blockTitles.seeAll')}</S.SeeAllButton>
          </S.FeedBlockHeader>
          <S.SnapCarousel
            size={isBig ? 'big' : 'small'}
            itemCount={data.length}
            onEndReached={handleEndReached}
            renderItem={renderItem}
            isLoading={isLoading}
            isError={isError}
            onReload={handleReload}
          />
        </>
      ) : type === 'todo' ? (
        <S.FeedBlockHeader isRtl={isRtl}>
          <S.FeedBlockTitle isRtl={isRtl}>{t('feed.noTodo', { firstName })}</S.FeedBlockTitle>
        </S.FeedBlockHeader>
      ) : null}
    </S.FeedBlock>
  );
};

const S = {
  FeedBlock: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  `,
  FeedBlockHeader: styled.div<{ isRtl?: boolean }>`
    display: flex;
    justify-content: space-between;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    margin: 0 20px;
  `,
  FeedBlockTitle: styled.span<{ isRtl?: boolean }>`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  `,
  SeeAllButton: styled(Link)`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 400;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  `,
  SnapCarousel: styled(SnapCarousel)`
    margin-top: 16px;
  `,
};

export default memo(FeedBlock);
