import React, { useRef, memo, useCallback } from 'react';
import styled from 'styled-components';
import { TSize } from './FeedResultItem';
import { FeedBlockItemSkeleton } from './Skeleton';
import InfoBlock from './InfoBlock';
import { getIsRtl } from '../../../locale/i18n';

interface IProps {
  onEndReached: () => void;
  itemCount: number;
  className?: string;
  renderItem: (index: number) => React.ReactNode;
  size: TSize;
  isLoading: boolean;
  isError: boolean;
  onReload: () => void;
}

const SIZE_MAP = {
  width: {
    big: 229,
    medium: 180,
    small: 140,
  },
  height: {
    big: 219 / 0.7 + 50,
    medium: 170 / 0.7 + 50,
    small: 130 / 0.7 + 50,
  },
};

const SnapCarousel: React.FC<IProps> = ({
  onEndReached,
  renderItem,
  itemCount,
  size,
  isLoading,
  isError,
  onReload,
  className,
}) => {
  const isRtl = getIsRtl();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) {
      return;
    }
    const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
    const adjustedScrollLeft = isRtl ? Math.abs(scrollLeft) : scrollLeft;
    if (scrollWidth - adjustedScrollLeft - clientWidth < SIZE_MAP.width[size] * 2 && !isLoading && !isError) {
      onEndReached();
    }
  }, [isError, isLoading, isRtl, onEndReached, size]);

  const totalItems = itemCount + (isLoading ? 3 : 0) + (isError ? 1 : 0);

  return (
    <S.OuterList
      size={size}
      dir={isRtl ? 'rtl' : 'ltr'}
      ref={containerRef}
      onScroll={handleScroll}
      className={className}
    >
      <S.InnerList>
        {Array.from({ length: totalItems }).map((_, index) => {
          if (index < itemCount) {
            return (
              <S.ItemWrapper isRtl={isRtl} key={index} size={size}>
                {renderItem(index)}
              </S.ItemWrapper>
            );
          }

          if (isLoading && index >= itemCount) {
            return (
              <S.ItemWrapper isRtl={isRtl} key={`skeleton-${index}`} size={size}>
                <FeedBlockItemSkeleton size={size} />
              </S.ItemWrapper>
            );
          }

          if (isError && index >= itemCount) {
            return (
              <S.ItemWrapper isRtl={isRtl} key='error' size={size}>
                <InfoBlock onReload={onReload} size={size} isError />
              </S.ItemWrapper>
            );
          }

          // shouldn’t happen
          return null;
        })}
      </S.InnerList>
    </S.OuterList>
  );
};

const S = {
  InnerList: styled.div`
    display: flex;
    flex-direction: row;
    min-width: auto;
  `,
  OuterList: styled.div<{ size: TSize }>`
    max-width: 100%;
    width: 100vw;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: ${({ size }) => (size === 'big' ? 8 : size === 'medium' ? 13 : 11)}px;
    scroll-padding: 0 20px;
    padding: 0 20px;

    scrollbar-width: none;
    -ms-overflow-style: none;
    min-width: auto;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  ItemWrapper: styled.div<{ isFirst?: boolean; size: TSize; isRtl?: boolean }>`
    scroll-snap-align: start;
    flex: 0 0 ${({ size }) => SIZE_MAP.width[size]}px;
    ${({ isRtl, isFirst }) => `${isRtl ? 'padding-right' : 'padding-left'}: ${isFirst ? '26px' : '0'}`};
    transition: scroll-snap-align 0.2s ease-in-out;
  `,
};

export default memo(SnapCarousel);
