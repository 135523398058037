const PlaylistActionTypes = {
  PLAYLIST_QUERY_REQUEST: 'PLAYLIST_QUERY_REQUEST',
  PLAYLIST_QUERY_SUCCESS: 'PLAYLIST_QUERY_SUCCESS',
  PLAYLIST_LOAD_NEXT: 'PLAYLIST_LOAD_NEXT',
  PLAYLIST_LOAD_NEXT_SUCCESS: 'PLAYLIST_LOAD_NEXT_SUCCESS',
  FETCH_SELECTED_PLAYLIST_REQUEST: 'FETCH_SELECTED_PLAYLIST_REQUEST',
  FETCH_SELECTED_PLAYLIST_SUCCESS: 'FETCH_SELECTED_PLAYLIST_SUCCESS',
  RESET_SELECTED_PLAYLIST: 'RESET_SELECTED_PLAYLIST',
  SET_NEW_PLAYLISTS_TO_MAP: 'SET_NEW_PLAYLISTS_TO_MAP',
};

export default PlaylistActionTypes;
