import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeedResultItem, GetFeedActionPayload, InitialState, Key } from './feed.types';
import { getDataWithStateValue, getDTDataWithStateValue } from '../slice.helpers';
import { FEED_BLOCK_CONFIGS } from './feed.constants';

const initialState: InitialState = {
  totalFeedResultsNumber: getDataWithStateValue(),
  todo: FEED_BLOCK_CONFIGS.todo.lists.map(() => getDTDataWithStateValue()),
  recommended: FEED_BLOCK_CONFIGS.recommended.lists.map(() => getDTDataWithStateValue()),
  // categories: {},
  mandatory: FEED_BLOCK_CONFIGS.mandatory.lists.map(() => getDTDataWithStateValue()),
  allContent: FEED_BLOCK_CONFIGS.allContent.lists.map(() => getDTDataWithStateValue()),
};

const SLICE_NAME = 'feed';

const reducers = {
  // totalFeedResultsNumber
  getTotalFeedResultsNumber: (state) => {
    state.totalFeedResultsNumber.isLoading = true;
    state.totalFeedResultsNumber.error = null;
  },
  setTotalFeedResultsNumber: (state, { payload }: PayloadAction<number>) => {
    state.totalFeedResultsNumber.data = payload;
    state.totalFeedResultsNumber.isLoading = false;
    state.totalFeedResultsNumber.error = null;
  },
  setTotalFeedResultsNumberError: (state, { payload }: PayloadAction<any>) => {
    state.totalFeedResultsNumber.isLoading = false;
    state.totalFeedResultsNumber.error = payload;
  },

  // data
  setFeedIsLoading: (state, { payload }: PayloadAction<{ key: Key; listIndex: number; isLoading: boolean }>) => {
    const { key, listIndex, isLoading } = payload;
    state[key][listIndex].isLoading = isLoading;
  },
  setFeedPageData: (
    state,
    {
      payload,
    }: PayloadAction<{
      key: Key;
      listIndex: number;
      data: FeedResultItem[];
      page: number;
      totalPages: number;
      count: number;
    }>,
  ) => {
    const { key, listIndex, data, page, totalPages, count } = payload;

    const currentData = state[key][listIndex].data || [];
    state[key][listIndex].data = [...currentData, ...data];

    state[key][listIndex].page = page;
    state[key][listIndex].totalPages = totalPages;
    state[key][listIndex].count = count;

    state[key][listIndex].isLoading = false;
    state[key][listIndex].error = null;
  },
  setFeedError: (
    state,
    {
      payload,
    }: PayloadAction<{
      key: Key;
      listIndex: number;
      error: any;
    }>,
  ) => {
    const { key, listIndex, error } = payload;
    state[key][listIndex].isLoading = false;
    state[key][listIndex].error = error;
  },
  resetFeedBlock: (state, { payload: { key } }: PayloadAction<{ key: Key }>) => {
    state[key] = initialState[key];
  },
  resetFeed: (state) => {
    Object.entries(initialState).forEach(([key, value]) => {
      state[key] = value;
    });
  },
};

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers,
});

export const {
  getTotalFeedResultsNumber,
  setTotalFeedResultsNumber,
  setTotalFeedResultsNumberError,
  setFeedIsLoading,
  setFeedPageData,
  setFeedError,
  resetFeedBlock,
  resetFeed,
} = slice.actions;

export default slice.reducer;

export const getFeed = createAction<GetFeedActionPayload>(`${SLICE_NAME}/getFeed`);
