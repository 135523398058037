import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ReactComponent as QuestionIcon } from '../../../assets/icons/prompt-input.svg';
import { BaseTextArea } from '../../../components/shared/Inputs/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import {
  knowledgeErrorSelector,
  knowledgeIsLoadingSelector,
  knowledgeLocalPromptSelector,
  knowledgeRelatedContentSelector,
  knowledgeRequestPromptSelector,
  knowledgeTagsSearchDataSelector,
} from '../../../store/knowledge/knowledge.selectors';
import { fetchKnowledge, resetKnowledge, setLocalPrompt } from '../../../store/knowledge/knowledge.slice';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PencilIcon } from '../../../assets/icons/pencil.svg';
import { ReactComponent as EraserIcon } from '../../../assets/icons/eraser.svg';
import { ReactComponent as RedoIcon } from '../../../assets/icons/redo.svg';
import { ReactComponent as BackIcon } from '../../../assets/icons/back.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { getIsRtl as rtl } from '../../../locale/i18n';
import { log } from '../../../store/tracking/tracking.slice';
import {
  editModeInputButtonsCy,
  inputBackIconButtonCy,
  inputClearIconButtonCy,
  inputEditIconButtonCy,
  inputRedoIconButtonCy,
  inputSearchIconButtonCy,
  promptInputCy,
  watchModeInputButtonsCy,
} from '../aiPrompt.constants';

interface IProps {
  isEditMode: boolean;
  setIsEditMode: (isEditMode: boolean) => void;
}

const PromptInput: React.FC<IProps> = ({ isEditMode, setIsEditMode }) => {
  const isRtl = rtl();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const requestPrompt = useSelector(knowledgeRequestPromptSelector);
  const localPrompt = useSelector(knowledgeLocalPromptSelector);
  const isError = useSelector(knowledgeErrorSelector);
  const { searchRelated, answerRelated, answer } = useSelector(knowledgeRelatedContentSelector);
  const searchData = useSelector(knowledgeTagsSearchDataSelector);
  const isLoading = useSelector(knowledgeIsLoadingSelector);

  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const element = e.target;
      dispatch(setLocalPrompt(element.value));
      setIsEditMode(true);
    },
    [dispatch, setIsEditMode],
  );

  const handlePencilClick = useCallback(() => {
    dispatch(log({ event: 'PromptInput.handlePencilClick' }));
    inputRef.current.focus();
  }, [dispatch]);

  const handleRedoClick = useCallback(() => {
    dispatch(
      log({
        event: 'PromptInput.handleRedoClick',
        data: { prompt: requestPrompt },
      }),
    );

    if (!requestPrompt) {
      return;
    }

    dispatch(fetchKnowledge(requestPrompt));
  }, [dispatch, requestPrompt]);

  const handleEraserClick = useCallback(() => {
    dispatch(log({ event: 'PromptInput.handleEraserClick', data: { prompt: localPrompt } }));
    dispatch(resetKnowledge({ excludeFields: ['history'] }));
    inputRef.current.focus();
    setIsEditMode(false);
  }, [dispatch, localPrompt, setIsEditMode]);

  const handleApplyEdit = useCallback(() => {
    if (!localPrompt.trim()) {
      return;
    }

    dispatch(
      log({
        event: 'PromptInput.handleApplyEdit',
        data: { prompt: localPrompt },
      }),
    );

    dispatch(fetchKnowledge(localPrompt));
    setIsEditMode(false);
  }, [dispatch, localPrompt, setIsEditMode]);

  const handleFocus = useCallback(() => {
    if (!localPrompt.length && !answer) {
      return;
    }
    setIsEditMode(true);
  }, [answer, localPrompt.length, setIsEditMode]);

  const handleBackClick = useCallback(() => {
    dispatch(log({ event: 'PromptInput.handleBackClick', data: { prompt: localPrompt, requestPrompt } }));
    dispatch(setLocalPrompt(requestPrompt));
    setIsEditMode(false);
  }, [dispatch, localPrompt, requestPrompt, setIsEditMode]);

  const handleEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key !== 'Enter') {
        return;
      }

      e.preventDefault();
      handleApplyEdit();
    },
    [handleApplyEdit],
  );

  useEffect(() => {
    // workaround to fix the issue with the textarea height
    // we want to force recalculate the height when value changes
    inputRef.current.style.height = '1px';
    inputRef.current.style.height = 5 + inputRef.current.scrollHeight + 'px';
  }, [localPrompt]);

  return (
    <S.Container>
      <S.TopContainer isRtl={isRtl}>
        <S.QuestionIconContainer>
          <S.QuestionIcon />
        </S.QuestionIconContainer>
        <S.Input
          data-cy={promptInputCy}
          ref={inputRef}
          disabled={isLoading}
          value={localPrompt}
          onInput={handleChange}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyPress={handleEnterPress}
          placeholder={t('knowledge.inputPlaceholder')}
          isRtl={isRtl}
          autoFocus
        />
      </S.TopContainer>
      {(answer || searchRelated || answerRelated || searchData || isError) && !isLoading ? (
        isEditMode ? (
          <S.IconsContainer data-cy={editModeInputButtonsCy} isRtl={isRtl}>
            <S.IconButton data-cy={inputBackIconButtonCy} onMouseDown={handleBackClick}>
              <S.BackIcon />
            </S.IconButton>
            {'|'}
            <S.IconButton data-cy={inputSearchIconButtonCy} onMouseDown={handleApplyEdit}>
              <S.SearchIcon />
            </S.IconButton>
          </S.IconsContainer>
        ) : (
          <S.IconsContainer data-cy={watchModeInputButtonsCy} isRtl={isRtl}>
            <S.IconButton data-cy={inputEditIconButtonCy} onClick={handlePencilClick}>
              <S.PencilIcon />
            </S.IconButton>
            {'|'}
            <S.IconButton data-cy={inputRedoIconButtonCy} onClick={handleRedoClick}>
              <S.RedoIcon />
            </S.IconButton>
            {'|'}
            <S.IconButton data-cy={inputClearIconButtonCy} onClick={handleEraserClick}>
              <S.EraserIcon />
            </S.IconButton>
          </S.IconsContainer>
        )
      ) : null}
    </S.Container>
  );
};

const S = {
  TopContainer: styled.div<{ isRtl: boolean }>`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
  `,
  Container: styled.div`
    width: 100%;
    min-height: 55px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 16px;
  `,
  Input: styled(BaseTextArea)<{ isRtl: boolean }>`
    width: 100%;
    border: none;
    margin-top: 3px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.text};
    background-color: #fff;
    ${({ isRtl }) => (isRtl ? 'margin-right' : 'margin-left')}: 9px;
    word-break: break-word;
    resize: none;
    overflow: hidden;

    &:focus {
      outline: none;
    }
  `,
  QuestionIcon: styled(QuestionIcon)``,
  QuestionIconContainer: styled.div`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.1);
  `,
  IconsContainer: styled.div<{ isRtl: boolean }>`
    display: flex;
    flex-direction: ${({ isRtl }) => (isRtl ? 'row-reverse' : 'row')};
    align-items: center;
    justify-content: center;
    align-self: ${({ isRtl }) => (isRtl ? 'flex-start' : 'flex-end')};
    color: ${({ theme }) => theme.colors.lightGray11};
    margin-top: 3px;
  `,
  PencilIcon: styled(PencilIcon)`
    width: 20px;
    height: 20px;
  `,
  RedoIcon: styled(RedoIcon)`
    width: 20px;
    height: 20px;
  `,
  EraserIcon: styled(EraserIcon)`
    width: 20px;
    height: 20px;
  `,
  IconButton: styled.div`
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin: 0 10px;
  `,
  BackIcon: styled(BackIcon)`
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SearchIcon: styled(SearchIcon)`
    width: 20px;
    height: 20px;
    fill: ${({ theme }) => theme.colors.primaryBlue};
  `,
};

export default PromptInput;
