import React, { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getIsRtl } from '../../locale/i18n';
import { Loader } from '../shared';
import {
  knowledgeIsLoadingSelector,
  knowledgeRequestPromptSelector,
  knowledgeRelatedContentSelector,
  knowledgeTagsSearchDataCountSelector,
} from '../../store/knowledge/knowledge.selectors';
import InfiniteScroll from 'react-infinite-scroll-component';
import { relatedContentCy, relatedContentItemCy } from '../../screens/Prompt/aiPrompt.constants';
import { playlistsMapSelector } from '../../store/playlist/playlist.selectors';
import KnowledgeListItem from './KnowledgeListItem';
import { APP_WIDTH } from '../../utils/constants';

interface IProps {
  orgId?: number;
  isPublicFeed?: boolean;
}

const KnowledgeList: FC<IProps> = () => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();

  const prompt = useSelector(knowledgeRequestPromptSelector);
  const { answer, searchRelated, answerRelated } = useSelector(knowledgeRelatedContentSelector);
  // const searchData = useSelector(knowledgeTagsSearchDataSelector);
  const isLoading = useSelector(knowledgeIsLoadingSelector);
  // const isSearchDataLoading = useSelector(knowledgeTagsSearchDataIsLoadingSelector);
  const searchCount = useSelector(knowledgeTagsSearchDataCountSelector);

  const playlistsMap = useSelector(playlistsMapSelector);

  const relatedCount = (searchRelated?.length || 0) + (answerRelated?.length || 0);
  const dataLength = relatedCount; //+ (searchData?.length || 0);
  const hasMore = dataLength < relatedCount + searchCount;

  // const searchDisplayedData = useMemo(
  //   () =>
  //     searchData?.filter(
  //       (searchBiteShare) =>
  //         !searchRelated?.some((searchRelatedBiteShare) => searchRelatedBiteShare.id === searchBiteShare.id) &&
  //         !answerRelated?.some((answerRelatedBiteShare) => answerRelatedBiteShare.id === searchBiteShare.id),
  //     ),
  //   [searchData, searchRelated, answerRelated],
  // );

  // const handleNext = useCallback(() => {
  //   if (isSearchDataLoading) {
  //     return;
  //   }
  //   dispatch(fetchTagSearchData());
  // }, [dispatch, isSearchDataLoading]);

  const isEmptyList = !answerRelated?.length && !searchRelated?.length && !prompt.length; // || !searchData?.length);

  const LoaderComponent = useCallback(
    () => (
      <S.LoaderWrapper>
        <Loader />
      </S.LoaderWrapper>
    ),
    [],
  );

  // show nothing if there is no data
  if (isEmptyList) {
    return null;
  }

  // show nothing if there is no answer and it's loading
  if (!answer?.length && isLoading) {
    return null;
  }

  return (
    <S.Container>
      <S.SectionTitle data-cy={relatedContentCy}>{t('knowledge.relevantBites')}</S.SectionTitle>
      <S.List
        scrollableTarget='app-container'
        dataLength={dataLength}
        // next={handleNext}
        hasMore={hasMore}
        loader={isLoading ? null : <LoaderComponent />}
      >
        {isLoading ? (
          <LoaderComponent />
        ) : (
          <>
            {answerRelated &&
              answerRelated.map((bite) => (
                <>
                  <KnowledgeListItem
                    dataCy={relatedContentItemCy}
                    key={bite.id}
                    data={bite}
                    size={'medium'}
                    contentType='bite'
                  />
                  {playlistsMap[bite.playlist] && (
                    <KnowledgeListItem
                      key={`${bite.id}-${bite.playlist}`}
                      data={playlistsMap[bite.playlist]}
                      size={'medium'}
                      contentType={playlistsMap[bite.playlist].is_quiz ? 'quiz' : 'playlist'}
                    />
                  )}
                </>
              ))}
            {searchRelated &&
              !answerRelated.length &&
              searchRelated.map((bite) => (
                <>
                  <KnowledgeListItem
                    key={bite.id}
                    dataCy={relatedContentItemCy}
                    data={bite}
                    size={'medium'}
                    contentType='bite'
                  />
                  {playlistsMap[bite.playlist] && (
                    <KnowledgeListItem
                      key={`${bite.id}-${bite.playlist}`}
                      data={playlistsMap[bite.playlist]}
                      size={'medium'}
                      contentType={playlistsMap[bite.playlist].is_quiz ? 'quiz' : 'playlist'}
                    />
                  )}
                </>
              ))}
            {/*{searchDisplayedData &&*/}
            {/*  searchDisplayedData.map((bite) => (*/}
            {/*    <BiteItem*/}
            {/*      dataCy={searchContentItemCy}*/}
            {/*      bite={bite}*/}
            {/*      withoutProgressIndication={withoutProgressIndication}*/}
            {/*      onClick={onBiteClick}*/}
            {/*      key={bite.id}*/}
            {/*    />*/}
            {/*  ))}*/}
          </>
        )}
      </S.List>
    </S.Container>
  );
};

const S: any = {
  Container: styled.div`
    margin-top: 32px;
  `,
  List: styled(InfiniteScroll)`
    width: 100vw;
    max-width: ${APP_WIDTH}px;
    display: grid;
    grid-template-columns: repeat(2, minmax(170px, max-content));
    grid-column-gap: 13px;
    grid-row-gap: 16px;
    grid-auto-flow: dense;
    padding-bottom: 20px;
    ${() => (getIsRtl() ? 'direction: rtl;' : '')};
    writing-mode: horizontal-tb;
    justify-items: center;
    justify-content: center;
    align-items: flex-start;
  `,
  Divider: styled.div`
    grid-column: 1 / -1;
    margin-top: 10px;
    width: 100%;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.lightGray1};
  `,
  SectionTitle: styled.div`
    padding: 0 18px;
    margin-bottom: 23px;
    width: 100%;
    grid-column: 1 / -1;
    font-size: 15px;
    font-weight: 400;
  `,
  LoaderWrapper: styled.div`
    margin: 20px;
  `,
};

export default KnowledgeList;
