import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import BottomSheet from '../../../components/shared/BottomSheet';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoWithStars } from '../../../assets/icons/logo-with-stars.svg';
import { useHistory } from 'react-router-dom';
import { getIsRtl as rtl } from '../../../locale/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { log } from '../../../store/tracking/tracking.slice';
import { orgSelector } from '../../../store/organization/organization.selectors';
import { askMeAnythingCy } from '../../Prompt/aiPrompt.constants';
import { organizationHasFeatureFlagSelector } from '../../../store/profile/profile.selectors';
import { Features } from '../../../utils/featureFlag/featureFlag.types';
import { getOrganizationFeatureFlags } from '../../../store/profile/profile.actions';
import { usePopoverHighlight } from '../../../hooks/usePopoverHighlight';

const AskMeAnythingBottomSheet = () => {
  const dispatch = useDispatch();
  const isRtl = rtl();
  const { t } = useTranslation();
  const history = useHistory();
  const { id: orgId } = useSelector(orgSelector);
  const askAiFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.ASK_AI_FEED,
    }),
  );

  const bottomSheetRef = useRef<HTMLDivElement>(null);

  const { driverRef } = usePopoverHighlight({
    displayedLocalStorageKey: 'viewedHomeAskAiPopoverHighlight',
    clickedLocalStorageKey: 'clickedHomeAskAiPopoverHighlight',
    text: t('screens.home.homeAskAiPopoverHighlight'),
    ref: bottomSheetRef,
    delay: 2000,
    side: 'top',
  });

  useEffect(() => {
    const driver = driverRef.current;

    return () => {
      driver?.destroy();
    };
  }, [driverRef]);

  const handleAskMeButtonClick = useCallback(() => {
    dispatch(log({ event: 'AskMeAnythingBottomSheet.handleAskMeButtonClick' }));

    driverRef.current?.destroy();
    localStorage.setItem('clickedHomeAskAiPopoverHighlight', '1');

    history.push('/prompt');
  }, [dispatch, driverRef, history]);

  useEffect(() => {
    dispatch(getOrganizationFeatureFlags(orgId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  if (!askAiFeatureFlag) {
    return null;
  }

  return (
    <BottomSheet containerRef={bottomSheetRef}>
      {/*<S.AskMeText>{t('knowledge.searchOrAskMe')}</S.AskMeText>*/}
      <S.AskMeButton data-cy={askMeAnythingCy} onClick={handleAskMeButtonClick}>
        <S.AskMeIconContainer>
          <LogoWithStars />
        </S.AskMeIconContainer>
        <S.AskMeButtonText isRtl={isRtl}>{t('knowledge.askMeAnything')}</S.AskMeButtonText>
      </S.AskMeButton>
    </BottomSheet>
  );
};

const S = {
  AskMeButton: styled.div`
    display: flex;
    align-items: center;
    height: 45px;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.primaryBlue};
    border-radius: ${45 / 2}px;
    margin: 12px;
    cursor: pointer;
  `,
  AskMeButtonText: styled.span<{ isRtl: boolean }>`
    width: 100%;
    font-size: 15px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.lightText};
    margin-left: -4px;
    margin-right: 20px;
    direction: ${({ isRtl }) => (isRtl ? 'rtl' : 'ltr')};
  `,
  AskMeIconContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
  `,
  AskMeText: styled.div`
    width: 100%;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    color: ${({ theme }) => theme.colors.primaryBlue};
    margin: 12px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
  `,
};

export default AskMeAnythingBottomSheet;
