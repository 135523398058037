import React, { memo } from 'react';
import styled from 'styled-components';
import { TSize } from './FeedResultItem';
import { useTranslation } from 'react-i18next';

const SIZE_MAP = {
  coverWidth: {
    big: 219,
    medium: 170,
    small: 130,
  },
  coverHeight: {
    big: 219 / 0.7,
    medium: 170 / 0.7,
    small: 130 / 0.7,
  },
  textWidth: {
    big: 156,
    medium: 121,
    small: 93,
  },
};

interface IProps {
  size?: TSize;
  fullWidth?: boolean;
  isError?: boolean;
  onReload: () => void;
}

const InfoBlock: React.FC<IProps> = ({ size = 'small', fullWidth, onReload, isError }) => {
  const { t } = useTranslation();

  return (
    <S.Container isError={isError} fullWidth={fullWidth} size={size}>
      <S.ErrorBlockText fullWidth={fullWidth}>
        {isError ? t('common.somethingWentWrong') : t('common.noData')}
      </S.ErrorBlockText>
      <S.ReloadButton fullWidth={fullWidth} onClick={onReload}>
        {t('feed.refresh')}
      </S.ReloadButton>
    </S.Container>
  );
};

const S = {
  Container: styled.div<{ size: TSize; fullWidth: boolean; isError?: boolean }>`
    display: flex;
    width: ${({ size, fullWidth }) => (fullWidth ? '100%' : `${SIZE_MAP.coverWidth[size]}px`)};
    height: ${({ size }) => SIZE_MAP.coverHeight[size]}px;
    background-color: ${({ theme, isError }) => (isError ? theme.colors.lightGray23 : theme.colors.info)};

    border-radius: 16px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  ErrorBlockText: styled.div<{ fullWidth: boolean }>`
    display: flex;
    font-size: ${({ fullWidth }) => (fullWidth ? 16 : 13)}px;
    font-weight: 700;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
  `,
  ReloadButton: styled.div<{ fullWidth: boolean }>`
    display: flex;
    font-size: ${({ fullWidth }) => (fullWidth ? 16 : 13)}px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    text-decoration: underline;
  `,
};

export default memo(InfoBlock);
