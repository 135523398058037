import React, { useCallback, useEffect, useMemo } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import MainApp from './MainApp';
import ViewBite from './ViewBite';
import ViewQuiz from './ViewQuiz';

import Auth from '../screens/auth/Auth';
import { isAuthenticatedSelector, isInitialAuthInProgressSelector } from '../store/auth/auth.selectors';
import useCheckForRedirections from '../hooks/useCheckForRedirections';
import TeamsMsgExtSettings from '../screens/TeamsMsgExtSettings/TeamsMsgExtSettings';
import useIsInsideMsTeams from '../hooks/useIsInsideMsTeams';
import { NonMicrosoft } from '../screens/auth/NonMicrosoftLogin/NonMicrosoft';
import ViewPlaylist from '../screens/ViewPlaylist';
import useInitApp from '../hooks/useInitApp';
import MsTeamsLogin from '../screens/auth/MsTeamsLogin';
import MsTeamsLoginPopup from '../screens/auth/MsTeamsLoginPopup';
import MsTeamsAuthStart from '../screens/auth/MsTeamsAuth';
import InitialScreen from '../screens/auth/InitialScreen';
import { log } from '../store/tracking/tracking.slice';
import TermsAndConditions from '../screens/auth/TermsAndConditions';
import { Loader } from '../components/shared';
import { isAuthDataMissingSelector } from '../store/authForm/authForm.selectors';
import history from './history';
import PublicFeed from '../screens/Home/PublicFeed';
import Prompt from '../screens/Prompt/Prompt';
import localStorage from '../utils/localStorage';
import { signupEnabledSelector } from '../store/organization/organization.selectors';
import FocusedFeedBlock from '../screens/Feed/FocusedFeedBlock';
import Div100vhDeviceOnly from '../components/shared/Div100vhDeviceOnly';

const InitApp = () => {
  const dispatch = useDispatch();

  useCheckForRedirections();
  const isAppReady = useInitApp();

  useEffect(() => {
    dispatch(
      log({
        event: 'Routes InitApp: isAppReady changed',
        data: {
          isAppReady,
        },
      }),
    );
  }, [dispatch, isAppReady]);

  if (!isAppReady) {
    return null;
  }

  return <Routes />;
};

export interface IPublicFeedConfig {
  path: string;
  defaultSubPath: string;
  orgId: number;
}
export const PUBLIC_FEED_ROUTES: IPublicFeedConfig[] = [
  {
    path: '/iron-swords',
    defaultSubPath: 'playlists',
    orgId: 36,
  },
];
const PUBLIC_FEED_ROUTES_PATHS = PUBLIC_FEED_ROUTES.map(({ path }) => path);

const Routes = () => (
  <Switch>
    <Route path={PUBLIC_FEED_ROUTES_PATHS} component={PublicFeedRoutes} />
    <Route path='/auth' component={AuthRoutes} />
    <Route path='/teams-msg-ext-settings' component={TeamsMsgExtSettings} />
    <Route path='/bites/:id' component={ViewBite} />
    <Route path='/quiz/:quizId' component={ViewQuiz} />
    <Route path='/playlists/:id' component={ViewPlaylist} />
    <Route path='/' component={ProtectedRoutes} />
    <Route component={() => <div>Page not found..</div>} />
  </Switch>
);

const PublicFeedRoutes = () => {
  return <>{PUBLIC_FEED_ROUTES.flatMap(PublicFeedRoutesSwitch)}</>;
};

const PublicFeedRoutesSwitch = (config: IPublicFeedConfig) => {
  const { path, defaultSubPath } = config;

  const renderComponent = useCallback((props) => <PublicFeed {...props} config={config} />, [config]);
  const renderRedirect = useCallback(
    ({ location }) => (
      <Redirect
        to={{
          ...location,
          pathname: `${path}/${defaultSubPath}`,
        }}
      />
    ),
    [defaultSubPath, path],
  );
  const paths = useMemo(() => [`${path}/bites`, `${path}/playlists`], [path]);

  return (
    <Div100vhDeviceOnly>
      <Switch key={path}>
        <Route path={paths} component={renderComponent} />
        <Route exact path={path} component={renderRedirect} />
      </Switch>
    </Div100vhDeviceOnly>
  );
};

const ProtectedRoutes = () => {
  const dispatch = useDispatch();

  const isInitialAuthInProgress = useSelector(isInitialAuthInProgressSelector);

  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isAuthDataMissing = useSelector(isAuthDataMissingSelector);
  const { isInsideMsTeams } = useIsInsideMsTeams();

  useEffect(() => {
    dispatch(
      log({
        event: 'Routes ProtectedRoutes: isAuthenticated changed',
        data: {
          isAuthenticated,
        },
      }),
    );
  }, [dispatch, isAuthenticated]);

  if (!isAuthenticated && localStorage.getItem('token')) {
    return (
      <Div100vhDeviceOnly>
        <Loader />
      </Div100vhDeviceOnly>
    );
  }

  if (isAuthenticated && !isAuthDataMissing) {
    return (
      <Switch>
        <Route path='/feed/:type' component={FocusedFeedBlock} />
        <Route path={['/feed', '/join-organization']} component={MainApp} />
        <Route path='/set-your-profile' component={Auth} />
        <Route path='/prompt' component={Prompt} />
        <Route
          exact
          path='/'
          component={({ location }) => (
            <Redirect
              to={{
                ...location,
                pathname: '/feed',
              }}
            />
          )}
        />
      </Switch>
    );
  }

  if (isInitialAuthInProgress) {
    return (
      <Div100vhDeviceOnly>
        <Loader />
      </Div100vhDeviceOnly>
    );
  }

  return (
    <Redirect
      to={{
        ...history.location,
        pathname: isInsideMsTeams ? '/auth/msteams-popup' : isAuthDataMissing ? '/auth/signin' : '/auth',
      }}
    />
  );
};

const AuthRoutes = () => {
  const dispatch = useDispatch();

  const signupEnabled = useSelector(signupEnabledSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isAuthDataMissing = useSelector(isAuthDataMissingSelector);

  useEffect(() => {
    dispatch(
      log({
        event: 'Routes AuthRoutes: isAuthenticated changed',
        data: {
          isAuthenticated,
          isAuthDataMissing,
        },
      }),
    );
  }, [dispatch, isAuthDataMissing, isAuthenticated]);

  const renderAuthStack = useMemo(() => {
    if (isAuthenticated && !isAuthDataMissing) {
      return (
        <Switch>
          <Route path='/auth/password-recovery/create-new-password' component={Auth} />
          <Redirect
            to={{
              ...history.location,
              pathname: '/',
            }}
          />
        </Switch>
      );
    }

    return (
      <Switch>
        <Route exact path='/auth' component={InitialScreen} />
        <Route path='/auth/signin' component={Auth} />
        {signupEnabled ? (
          <Route exact path='/auth/signup' component={Auth} />
        ) : (
          <Redirect
            to={{
              ...history.location,
              pathname: '/auth',
            }}
          />
        )}

        <Route path='/auth/nonmicrosoft' component={NonMicrosoft} />
        <Route exact path='/auth/msteams' component={MsTeamsLogin} />
        <Route path='/auth/msteams-popup' component={MsTeamsLoginPopup} />
        <Route path='/auth/msteams/start' component={MsTeamsAuthStart} />
        <Route path='/auth/password-recovery/create-new-password' component={Auth} />
        <Route path='/auth/signup/terms_and_conditions' component={TermsAndConditions} />
      </Switch>
    );
  }, [isAuthenticated, isAuthDataMissing, signupEnabled]);

  return <Div100vhDeviceOnly>{renderAuthStack}</Div100vhDeviceOnly>;
};

export default InitApp;
