import { useHistory, useParams } from 'react-router-dom';
import { FEED_BLOCK_CONFIGS } from '../../store/feed/feed.constants';
import React, { memo, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/shared/Buttons/BackButton';
import { HEADER_HEIGHT } from '../../components/layout/StyledHeader';
import { getIsRtl } from '../../locale/i18n';
import FeedResultItem from './common/FeedResultItem';
import { useDispatch, useSelector } from 'react-redux';
import { keyDataSelector } from '../../store/feed/feed.selectors';
import { useDTListCombinedData } from '../../hooks/useDTListCombinedData';
import { useDTListCombinedHasMore } from '../../hooks/useDTListCombinedHasMore';
import { getFeed, resetFeedBlock } from '../../store/feed/feed.slice';
import { Key } from '../../store/feed/feed.types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FeedBlockItemSkeleton } from './common/Skeleton';
import { useDTListCombinedError } from '../../hooks/useDTListCombinedError';
import InfoBlock from './common/InfoBlock';
import AskMeAnythingBottomSheet from '../Home/components/AskMeAnythingBottomSheet';
import { APP_WIDTH } from '../../utils/constants';
import EmptyList from './common/EmptyList';
import { useDTListCombinedLoading } from '../../hooks/useDTListCombinedLoading';
import useInstallClassToHtml from '../../hooks/useInstallClassToHtml';
import { isMobile } from '../../utils/utils';
import CustomIntercomLauncher from '../../components/shared/CustomIntercomLauncher';
import { organizationHasFeatureFlagSelector } from '../../store/profile/profile.selectors';
import { Features } from '../../utils/featureFlag/featureFlag.types';
import { orgSelector } from '../../store/organization/organization.selectors';

const FocusedFeedBlock = () => {
  const dispatch = useDispatch();
  const isDevice = isMobile();
  const { t } = useTranslation();
  const { type } = useParams<{ type: Key }>();
  const history = useHistory();
  const feedBlockData = useSelector(keyDataSelector(type));
  const data = useDTListCombinedData(feedBlockData);
  const hasMore = useDTListCombinedHasMore(feedBlockData);
  const isLoading = useDTListCombinedLoading(feedBlockData);
  const isInitialLoading = feedBlockData?.[0].isLoading && feedBlockData?.[0].data === null;
  const isError = useDTListCombinedError(feedBlockData);
  const { id: orgId } = useSelector(orgSelector);
  const askAiFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.ASK_AI_FEED,
    }),
  );

  useInstallClassToHtml({ className: 'scrollable-block' });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (FEED_BLOCK_CONFIGS[type]) {
      return;
    }

    history.push('/');
  }, [history, type]);

  useEffect(() => {
    if (!data?.length && !isInitialLoading && !isError) {
      dispatch(getFeed({ key: type }));
    }
  }, [data?.length, dispatch, isError, isInitialLoading, type]);

  const handleNext = useCallback(() => {
    dispatch(getFeed({ key: type }));
  }, [dispatch, type]);

  const handleReload = useCallback(() => {
    dispatch(resetFeedBlock({ key: type }));
    dispatch(getFeed({ key: type }));
  }, [dispatch, type]);

  const handleGoBack = useCallback(() => {
    history.push('/');
  }, [history]);

  const renderSkeletons = useMemo(
    () => (
      <>
        <FeedBlockItemSkeleton size={'medium'} withoutMargin />
        <FeedBlockItemSkeleton size={'medium'} withoutMargin />
        <FeedBlockItemSkeleton size={'medium'} withoutMargin />
        <FeedBlockItemSkeleton size={'medium'} withoutMargin />
      </>
    ),
    [],
  );

  if (!FEED_BLOCK_CONFIGS[type]) {
    return null;
  }

  return (
    <S.Container>
      <S.Header>
        <BackButton onClick={handleGoBack} />
        <S.FeedBlockTitle>
          {type === 'todo' ? t('feed.blockTitles.focusedTodo') : t(FEED_BLOCK_CONFIGS[type].title)}
        </S.FeedBlockTitle>
        <S.EmptyDiv />
      </S.Header>
      {isInitialLoading ? (
        <S.LoaderContainer>{renderSkeletons}</S.LoaderContainer>
      ) : (
        <>
          {!data.length && !isError && !isLoading && <EmptyList onReload={handleReload} />}
          <S.FeedResultList
            dataLength={data.length}
            next={handleNext}
            hasMore={hasMore && !isError}
            scrollableTarget={!isDevice ? 'app-container' : undefined}
            loader={null}
          >
            {data.map((item) => (
              <FeedResultItem feedResultItem={item} size={'medium'} key={item.id} />
            ))}
            {isError && !isLoading && <InfoBlock size={'medium'} isError onReload={handleNext} />}
            {isLoading && renderSkeletons}
          </S.FeedResultList>
        </>
      )}

      <CustomIntercomLauncher marginBottom={askAiFeatureFlag && 75} />
      <AskMeAnythingBottomSheet />
    </S.Container>
  );
};

const S = {
  Container: styled.div`
    overflow: visible;
    flex: 1;
    width: 100vw;
    max-width: ${APP_WIDTH}px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  Header: styled.div`
    overflow: visible;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 24px;
    ${({ theme }) => css`
      color: ${theme.colors.text};
    `};
    ${() =>
      getIsRtl() &&
      `
			direction: rtl;
		`};
  `,
  FeedBlockTitle: styled.div`
    color: ${({ theme }) => theme.colors.black};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 15px;
    font-weight: 700;
  `,
  FeedResultList: styled(InfiniteScroll)`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 16px;
    grid-column-gap: 13px;
    grid-auto-flow: dense;
    padding-bottom: 100px;
    writing-mode: horizontal-tb;
    justify-items: center;
    align-items: flex-start;
    ${() => (getIsRtl() ? 'direction: rtl;' : '')};
  `,
  EmptyDiv: styled.div`
    width: 41px;
    height: 41px;
  `,
  LoaderContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 16px;
    grid-column-gap: 13px;
    grid-auto-flow: dense;
    padding-bottom: 100px;
    writing-mode: horizontal-tb;
    justify-items: center;
    align-items: flex-start;
    ${() => (getIsRtl() ? 'direction: rtl;' : '')};
  `,
};

export default memo(FocusedFeedBlock);
