import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

interface IProps {
  open: boolean;
  closeOnBlur?: boolean;
  withoutBackground?: boolean;
}

export const CollapsablePanel: FC<IProps> = ({ open, children }) => {
  const className = useMemo(() => {
    return open ? 'show' : '';
  }, [open]);

  return (
    <S.PopupContainer className={className}>
      <S.Popup className={className}>{children}</S.Popup>
      <S.BackDropContainer className={className} />
    </S.PopupContainer>
  );
};

const BackDropContainer = styled.div`
  cursor: pointer;
  width: 0;
  height: 0;
  position: absolute;
  z-index: 49;
  opacity: 0;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;

  &.show {
    width: 100%;
    height: 100%;
    opacity: 1;
    visibility: visible;
  }

  animation: 2s fadeOut ease 3s;
  animation-fill-mode: forwards;
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  visibility: hidden;
  background-color: #f5f5f5;
  animation: hideAnimation 0s ease-in 6s;
  animation-fill-mode: forwards;
  @keyframes hideAnimation {
    to {
      width: 0;
      height: 0;
    }
  }
`;

const Popup = styled.div`
  position: absolute;
  background-color: #f5f5f5;
  width: 100%;
  height: 30%;
  padding: 0;
  bottom: 0;
  box-sizing: border-box;
  transition: height 0.3;
  border-radius: 25px 25px 0 0;
  overflow: auto;
  max-height: 100dvh;
  visibility: hidden;
  opacity: 0;
  z-index: 50;
  &.show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
  animation: 2s popup linear 3s;
  animation-fill-mode: forwards;
  @keyframes popup {
    100% {
      height: 0;
    }
  }
`;

const CloseBtn = styled.div`
  cursor: pointer;
  text-align: right;
`;

const S = { PopupContainer, Popup, CloseBtn, BackDropContainer };
