import React, { FC, memo, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as EmptyFeedIcon } from '../../assets/icons/empty-feed.svg';
import { getTotalFeedResultsNumber } from '../../store/feed/feed.slice';
import { FEED_CONFIG } from '../../store/feed/feed.constants';
import FeedBlock from './common/FeedBlock';
import AiSuggestionBlock from './common/AiSuggestionBlock';
import { selectTotalFeedResultsNumber } from '../../store/feed/feed.selectors';
import { organizationHasFeatureFlagSelector } from '../../store/profile/profile.selectors';
import { Features } from '../../utils/featureFlag/featureFlag.types';
import { orgSelector } from '../../store/organization/organization.selectors';
import { APP_WIDTH } from '../../utils/constants';
import { useTranslation } from 'react-i18next';
import { authSelector } from '../../store/auth/auth.selectors';
import useInstallClassToHtml from '../../hooks/useInstallClassToHtml';

interface IProps {
  orgId?: number;
  isPublicFeed?: boolean;
  withoutProgressIndication?: boolean;
  onBiteClick?: (biteId: number, subject: string) => void;
}

const Feed: FC<IProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { first_name: firstName } = useSelector(authSelector);
  const totalFeedResultsNumber = useSelector(selectTotalFeedResultsNumber);
  const { id: orgId, name: workspaceName } = useSelector(orgSelector);
  const askAiFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.ASK_AI_FEED,
    }),
  );

  useInstallClassToHtml({ className: 'scrollable-block' });

  useEffect(() => {
    if (totalFeedResultsNumber === null) {
      dispatch(getTotalFeedResultsNumber());
    }
  }, [dispatch, totalFeedResultsNumber]);

  const renderFeedBlock = useCallback(
    (feedConfig) => {
      if (feedConfig.type !== 'ASK_AI') {
        return <FeedBlock key={`${feedConfig.key}-${orgId}`} type={feedConfig.key} isBig={feedConfig.isBig} />;
      }

      if (askAiFeatureFlag) {
        return <AiSuggestionBlock key={`AiSuggestionBlock-${orgId}`} />;
      }

      return null;
    },
    [askAiFeatureFlag, orgId],
  );

  if (totalFeedResultsNumber === 0) {
    return (
      <S.EmptyFeed>
        <S.EmptyFeedBoldText>{t('feed.emptyFeed.name', { firstName })}</S.EmptyFeedBoldText>
        <S.EmptyFeedText>{t('feed.emptyFeed.text', { workspaceName })}</S.EmptyFeedText>
        <EmptyFeedIcon width={70} height={44} />
      </S.EmptyFeed>
    );
  }

  return <S.List>{FEED_CONFIG.map(renderFeedBlock)}</S.List>;
};

const S: any = {
  List: styled.div`
    width: 100vw;
    max-width: ${APP_WIDTH}px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding-bottom: 100px;
  `,
  EmptyFeed: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
  `,
  EmptyFeedBoldText: styled.div`
    width: 340px;
    font-size: 15px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 27px;
  `,

  EmptyFeedText: styled.div`
    width: 340px;
    font-size: 15px;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 100px;
  `,
};

export default memo(Feed);
