import { all, put, select, spawn, takeEvery, takeLatest } from 'redux-saga/effects';
import { orgSelector } from '../organization/organization.selectors';
import withRetry from '../../utils/withRetry';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getFeed,
  getTotalFeedResultsNumber,
  setFeedIsLoading,
  setFeedPageData,
  setFeedError,
  setTotalFeedResultsNumber,
  setTotalFeedResultsNumberError,
} from './feed.slice';
import { getFeed as getFeedService } from '../api/calls/feed.calls';
import { logError } from '../tracking/tracking.slice';
import { FeedDTRequest, FeedResultItem, GetFeedActionPayload } from './feed.types';
import { FEED_BLOCK_CONFIGS } from './feed.constants';
import { keyDataSelector } from './feed.selectors';
import { DTResult } from '../types';
import { AxiosResponse } from 'axios';

function* getTotalFeedResultsNumberSaga() {
  const org = yield select(orgSelector);

  // yield put(log({ event: 'getTotalFeedResultsNumberSaga: start' }));
  try {
    const request: FeedDTRequest = {
      filters: {
        orgId: org.id,
      },
      sort: {
        feedItemId: 1,
      },
      pageSize: 1,
      page: 0,
    };

    const {
      data: { count },
    } = yield withRetry(() => getFeedService(request), {
      errorContext: {
        action: 'getFeedService',
        data: request,
      },
    });

    yield put(setTotalFeedResultsNumber(count));
    // yield put(log({ event: 'getTotalFeedResultsNumberSaga: done' }));
  } catch (error: any) {
    yield put(
      logError({
        event: 'getTotalFeedResultsNumberSaga: error',
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );
    yield put(setTotalFeedResultsNumberError(error));
  }
}

function* getFeedSaga({ payload }: Pick<PayloadAction<GetFeedActionPayload>, 'payload'>) {
  const { key, categoryId } = payload;

  const blockConfig = FEED_BLOCK_CONFIGS[key];

  // key is dynamic, make sure that it is correct
  if (!blockConfig) {
    yield put(logError({ event: 'getFeedSaga: invalid key', data: { key } }));
    return;
  }

  const blockData = yield select(keyDataSelector(key));

  const listIndex = blockConfig.lists.findIndex((list, index) => {
    return !blockData[index].data || blockData[index].page + 1 < blockData[index].totalPages;
  });

  if (listIndex === -1) {
    // yield put(log({ event: 'getFeedSaga: all data loaded' }));
    return;
  }

  const listConfig = blockConfig.lists[listIndex];
  const listData = blockData[listIndex];

  if (listData.isLoading) {
    return;
  }

  yield put(setFeedIsLoading({ key, listIndex: listIndex, isLoading: true }));

  const { filters, sort } = listConfig;
  const { page } = listData;
  const nextPage = page === null ? 0 : page + 1;

  const org = yield select(orgSelector);

  try {
    const request = {
      filters: {
        orgId: org.id,
        categoryId,
        ...filters,
      },
      sort,
      pageSize: 10,
      page: nextPage,
    };

    const {
      data: { results, totalPages, count },
    }: AxiosResponse<
      DTResult<{
        feedItem: FeedResultItem;
      }>
    > = yield withRetry(() => getFeedService(request), {
      errorContext: {
        action: 'getFeedService',
        data: request,
      },
    });

    const feedItems = results.map(({ feedItem }) => feedItem);

    yield put(
      setFeedPageData({
        key,
        listIndex,
        data: feedItems,
        page: nextPage,
        totalPages,
        count,
      }),
    );

    if (results.length < 6 && listIndex + 1 < blockConfig.lists.length) {
      yield spawn(getFeedSaga, { payload });
    }

    // yield put(log({ event: 'getFeedSaga: done' }));
  } catch (error: any) {
    yield put(
      logError({
        event: 'getFeedSaga: error',
        data: {
          errorMessage: error?.message,
          errorStack: error?.stack,
        },
      }),
    );

    yield put(
      setFeedError({
        key,
        listIndex,
        error,
      }),
    );
  }
}

export default function* feedSaga() {
  yield all([
    takeLatest(getTotalFeedResultsNumber, getTotalFeedResultsNumberSaga), //
    takeEvery(getFeed, getFeedSaga),
  ]);
}
