import React, { useRef, useEffect, useCallback, useMemo, memo, FC } from 'react';
// eslint-disable-next-line import/named
import styled, { css, DefaultTheme } from 'styled-components';

import { getIsRtl } from '../../locale/i18n';
import getLetters from '../../utils/letters';
import { Checkbox } from '../shared';
import { choiceItemDataSet } from './choiceItem.constants';

export interface IProps {
  choice: {
    id: number;
    text: string;
    percentage?: number;
    is_correct?: boolean;
  };
  questionColor?: string;
  isAnswered?: boolean;
  userChoicesIds?: number[];
  idx: number;
  choicesTranslations?: string[];
  onChoiceClick?: (choiceId: number) => void;
  withoutHightlight?: boolean;
  isMultiChoiceSurvey: boolean;
  isUserCorrect?: boolean;
  requireCorrectAnswer?: boolean;
}

const ChoiceItem: FC<IProps> = ({
  choice,
  idx,
  choicesTranslations,
  onChoiceClick,
  withoutHightlight,
  userChoicesIds,
  isAnswered,
  isMultiChoiceSurvey,
  isUserCorrect,
  requireCorrectAnswer,
}) => {
  const absoluteBackgroundGraphRef = useRef<HTMLElement>();

  const isCorrectIsKnown = 'is_correct' in choice;
  const isHighlightCorrectness = isAnswered && isCorrectIsKnown && !withoutHightlight;

  useEffect(() => {
    if (!isAnswered || isMultiChoiceSurvey || requireCorrectAnswer) {
      return;
    }
    const absoluteBackgroundGraphEl = absoluteBackgroundGraphRef.current;
    setTimeout(() => {
      absoluteBackgroundGraphEl.style.width = `${Math.ceil(choice.percentage)}%`;
    }, 100);
  }, [choice.percentage, isAnswered, isMultiChoiceSurvey, requireCorrectAnswer]);

  const handleOnClick = useCallback(() => {
    if ((isAnswered && !requireCorrectAnswer) || isUserCorrect) {
      return;
    }
    onChoiceClick(choice.id);
  }, [choice.id, isAnswered, isUserCorrect, onChoiceClick, requireCorrectAnswer]);

  const isSelected = useMemo(() => {
    return userChoicesIds.includes(choice.id);
  }, [choice.id, userChoicesIds]);

  const letter = getLetters()[idx];

  return (
    <S.Container
      id='questionChoice'
      isSelected={!isHighlightCorrectness && isSelected}
      shouldBeGreen={isUserCorrect && isHighlightCorrectness && choice.is_correct}
      shouldBeRed={isHighlightCorrectness && isSelected && !choice.is_correct}
      isAnswered={isAnswered && (!requireCorrectAnswer || isUserCorrect)}
      onClick={handleOnClick}
      data-cy={choiceItemDataSet.cy}
    >
      {!isMultiChoiceSurvey && <S.AbsoluteBackgroundGraph ref={absoluteBackgroundGraphRef} />}
      <S.Letter>{letter}</S.Letter>
      <S.ChoiceText dir='auto'>{choicesTranslations?.[idx] || choice.text}</S.ChoiceText>
      {!requireCorrectAnswer && !isMultiChoiceSurvey && 'percentage' in choice && (
        <S.Percent>{Math.ceil(choice.percentage)}%</S.Percent>
      )}
      {isMultiChoiceSurvey && (
        <S.CheckboxContainer>
          <Checkbox isChecked={isSelected} />
        </S.CheckboxContainer>
      )}
    </S.Container>
  );
};

const S: any = {};

const baseTextStyle = ({ theme, questionColor }: { theme: DefaultTheme; questionColor: string }) => css`
  z-index: 10;
  color: ${questionColor || theme.colors.darkGray4};
`;

S.AbsoluteBackgroundGraph = styled.div`
  position: absolute;
  width: 0%;
  left: 0;
  top: 0;
  height: 100%;
  transition: width 1.3s ease-in-out;
  ${({ theme }) => css`
    background-color: ${theme.colors.lightGray7};
  `};
  ${() =>
    getIsRtl() &&
    `
			left: auto;
			right: 0;
		`};
`;

S.Container = styled.div<{
  shouldBeGreen?: boolean;
  shouldBeRed?: boolean;
  isSelected?: boolean;
  isAnswered?: boolean;
}>`
  position: relative;
  justify-content: 'space-between';
  margin-bottom: 10px;
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  padding-inline-start: 10px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  ${({ theme, isSelected, shouldBeGreen, shouldBeRed, isAnswered }) => css`
    border: 3px solid ${theme.colors.lightGray7};
    ${isSelected && 'border-color: #1253fa'};
    ${shouldBeGreen &&
    css`
      border-color: ${theme.colors.green1};
      background-color: ${theme.colors.lightGreen1};
    `}
    ${shouldBeRed &&
    css`
      border-color: ${theme.colors.red1};
      background-color: ${theme.colors.lightRed1};
    `}
    cursor: ${isAnswered ? 'default' : 'pointer'};
  `};
  ${() =>
    getIsRtl() &&
    `
    padding-right: 20px;
    padding-left: 12px;
  `};
`;

S.TextContainer = styled.div`
  width: 88%;
  display: flex;
  flex-direction: row;
  z-index: 2;
`;

S.Letter = styled.p`
  ${baseTextStyle}
  margin-right: 13px;
  ${() =>
    getIsRtl() &&
    `
			margin-right: 0;
			margin-left: 13px;
		`};
`;

S.ChoiceText = styled.p`
  ${baseTextStyle}
  flex: 1;
`;

S.Percent = styled.p`
  z-index: 10;
  margin: 0 auto;
  font-size: 18px;

  ${({ theme }) => css`
    color: ${theme.colors.primaryBlue};
  `}
`;

S.CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default memo(ChoiceItem);
