import React, { FC, memo, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as FeedBiteIcon } from '../../../assets/icons/feed-bite-icon.svg';
import { ReactComponent as FeedPlaylistIcon } from '../../../assets/icons/feed-playlist-icon.svg';
import { ReactComponent as FeedQuizIcon } from '../../../assets/icons/feed-quiz-icon.svg';
import { Link } from 'react-router-dom';
import ProgressCircle from './ProgressCircle';
import { useTranslation } from 'react-i18next';
import { FeedResultItem as TFeedResultItem } from '../../../store/feed/feed.types';
import OrgLogo from '../../auth/common/OrgLogo';

export type TSize = 'big' | 'medium' | 'small';

interface IProps {
  feedResultItem: TFeedResultItem;
  size?: TSize;
}

const FeedResultItem: FC<IProps> = ({ feedResultItem, size = 'small' }) => {
  const { t } = useTranslation();

  const withPlaylistCover = !feedResultItem.coverUrl && !!feedResultItem.biteCoverUrls?.length;

  const link = useMemo(() => {
    if (feedResultItem.contentType === 'bite') {
      return `/bites/${feedResultItem.biteShareId}`;
    }

    if (feedResultItem.contentType === 'quiz') {
      return `/quiz/${feedResultItem.contentId}`;
    }

    return `/playlists/${feedResultItem.contentId}`;
  }, [feedResultItem.biteShareId, feedResultItem.contentId, feedResultItem.contentType]);

  const { isOverdue, dueDateMessage } = useMemo(() => {
    const due = new Date(feedResultItem.assignmentDueDate);
    const now = new Date();

    due.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);

    const diffInMs = due.getTime() - now.getTime();
    const diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24));

    if (diffInDays < 0) {
      return { isOverdue: true, dueDateMessage: t('feed.status.overdue') };
    }

    return {
      isOverdue: false,
      dueDateMessage: diffInDays < 1 ? t('feed.status.today') : t('feed.status.due', { due: diffInDays }),
    };
  }, [feedResultItem.assignmentDueDate, t]);

  const renderContentStatus = useMemo(() => {
    if (feedResultItem.isCompleted) {
      return <S.Completed size={size}>{t('feed.status.completed')}</S.Completed>;
    }

    if (feedResultItem.assignmentDueDate) {
      return (
        <S.AssignedDueDate isOverdue={isOverdue} size={size}>
          {dueDateMessage}
        </S.AssignedDueDate>
      );
    }

    return <S.Recommended size={size}>{t('feed.status.recommended')}</S.Recommended>;
  }, [dueDateMessage, feedResultItem.assignmentDueDate, feedResultItem.isCompleted, isOverdue, size, t]);

  const renderContentTypeIcon = useMemo(() => {
    switch (feedResultItem.contentType) {
      case 'bite':
        return <FeedBiteIcon />;
      case 'playlist':
        return <FeedPlaylistIcon />;
      case 'quiz':
        return <FeedQuizIcon />;
    }
  }, [feedResultItem.contentType]);

  return (
    <S.Container to={link} size={size}>
      <S.CoverImageContainer withPlaylistCover={withPlaylistCover} size={size} coverImage={feedResultItem.coverUrl}>
        {withPlaylistCover && (
          <S.PlaylistCoverContainer size={size}>
            {feedResultItem.biteCoverUrls.slice(0, 4).map((coverImage, idx) => (
              <S.PlaylistBiteItemCover size={size} key={idx} coverImage={coverImage} />
            ))}
          </S.PlaylistCoverContainer>
        )}
        {!feedResultItem.coverUrl && !feedResultItem.biteCoverUrls?.length && <OrgLogo />}
        <S.ContentTypeContainer>{renderContentTypeIcon}</S.ContentTypeContainer>
        <S.StatusContainer>{renderContentStatus}</S.StatusContainer>

        {!!feedResultItem.progress && (
          <S.RightContainer>
            <ProgressCircle size={size} progress={feedResultItem.progress} showPercents={size !== 'small'} />
          </S.RightContainer>
        )}
      </S.CoverImageContainer>
      <S.TextsContainer>
        <S.Subject>{feedResultItem.subject}</S.Subject>
        {feedResultItem.isNew && <S.NewText>{t('feed.status.new')}</S.NewText>}
      </S.TextsContainer>
    </S.Container>
  );
};

const SIZE_MAP = {
  container: {
    big: 219,
    medium: 170,
    small: 130,
  },
  statusFontSize: {
    big: 13,
    medium: 13,
    small: 12,
  },
  statusPadding: {
    big: '6px 10px',
    medium: '2px 6px',
    small: '2px 6px',
  },
  playlistCoverWidth: {
    big: (219 - 14) / 2,
    medium: (170 - 14) / 2,
    small: (130 - 14) / 2,
  },
  playlistCoverHeight: {
    big: (219 / 0.7 - 14) / 2,
    medium: (170 / 0.7 - 14) / 2,
    small: (130 / 0.7 - 14) / 2,
  },
};

const S = {
  Container: styled(Link)<{ size: TSize }>`
    display: block;
    width: ${({ size }) => SIZE_MAP.container[size]}px;
    cursor: pointer;
    position: relative;
    scroll-snap-align: start;

    &:hover {
      text-decoration: none;
    }
  `,
  CoverImageContainer: styled.div<{ size: TSize; coverImage: string; withPlaylistCover?: boolean }>`
    width: ${({ size }) => SIZE_MAP.container[size]}px;
    aspect-ratio: 0.7;
    margin-bottom: 10px;
    border-radius: ${({ withPlaylistCover }) => (withPlaylistCover ? 18 : 16)}px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    position: relative;

    ${({ theme, coverImage }) => css`
      background-image: url(${coverImage});
      background-color: ${theme.colors.lightBackground};
    `}
  `,
  TextsContainer: styled.div`
    font-size: 10px;
  `,
  Subject: styled.p`
    font-size: 15px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.darkGray1};
    font-weight: 300;
  `,

  StatusContainer: styled.div`
    position: absolute;
    bottom: 4px;
    left: 4px;
  `,
  RightContainer: styled.div`
    position: absolute;
    display: flex;
    bottom: 4px;
    right: 4px;
    align-items: center;
    justify-content: center;
  `,
  Recommended: styled.div<{ size: TSize }>`
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ size }) => SIZE_MAP.statusFontSize[size]}px;
    border-radius: 15px;
    display: flex;
    padding: ${({ size }) => SIZE_MAP.statusPadding[size]};
    align-items: center;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    font-weight: 400;
  `,
  AssignedDueDate: styled.div<{ size: TSize; isOverdue?: boolean }>`
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ size }) => SIZE_MAP.statusFontSize[size]}px;
    border-radius: 15px;
    display: flex;
    padding: ${({ size }) => SIZE_MAP.statusPadding[size]};
    align-items: center;
    color: white;
    background: ${({ isOverdue }) => (isOverdue ? '#FF3C27' : '#fe00c1')};
    font-weight: 400;
  `,
  Completed: styled.div<{ size: TSize }>`
    text-align: center;
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: ${({ size }) => SIZE_MAP.statusFontSize[size]}px;
    border-radius: 15px;
    display: flex;
    padding: ${({ size }) => SIZE_MAP.statusPadding[size]};
    align-items: center;
    color: white;
    background: linear-gradient(180deg, #17c868 0.4%, #62bd00 100%);
    font-weight: 700;
  `,
  NewText: styled.div`
    color: ${({ theme }) => theme.colors.primaryBlue};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-size: 14px;
    font-weight: 700;
  `,
  ContentTypeContainer: styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  PlaylistBiteItemCover: styled.div<{ size: TSize; coverImage: string }>`
    width: ${({ size }) => SIZE_MAP.playlistCoverWidth[size]}px;
    height: ${({ size }) => SIZE_MAP.playlistCoverHeight[size]}px;
    border-radius: 16px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    ${({ theme, coverImage }) => css`
      background-image: url(${coverImage});
      background-color: ${theme.colors.lightGray6};
    `}
  `,
  PlaylistCoverContainer: styled.div<{ size: TSize }>`
    width: ${({ size }) => SIZE_MAP.container[size]}px;
    aspect-ratio: 0.7;

    display: grid;
    grid-template-columns: repeat(2, minmax(48%, 1fr));
    grid-template-rows: repeat(2, minmax(48%, 1fr));

    grid-row-gap: 6px;
    grid-column-gap: 4px;

    padding: 4px 3px;

    border-radius: 16px;
    overflow: hidden;
    position: relative;
    background-color: #242529;
  `,
};

export default memo(FeedResultItem);
