import { EAuthFormType, EAuthFlowType } from '../../screens/auth/auth.types';

export interface IInitialState {
  currentFlow: EAuthFlowType;
  currentForm: EAuthFormType;
  employeeId: string;
  email: string;
  password: string;
  phone: string;
  isAuthDataMissing: boolean;
  firstName: string;
  lastName: string;
  recoveryToken?: string;
  userSelectedCountryCode?: string;
}

export enum EAuthMethods {
  EMAIL = 'email',
  PHONE = 'phone',
  GOOGLE = 'google',
  APPLE = 'apple',
  MICROSOFT = 'microsoft',
  SEVEN_SHIFTS_EMPLOYEE_ID = '7shifts_employee_id',
  AMAZON = 'amazon',
}

export interface IAuthDataMissingPayload {
  isAuthDataMissing: boolean;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
}
