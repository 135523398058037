import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../../components/shared';
import { getIsRtl } from '../../locale/i18n';
import { selectQuiz, selectedQuizSettingSelector } from '../../store/quiz/quiz.slice';
import { ReactComponent as QuizGood } from '../../assets/icons/quiz_good.svg';
import { ReactComponent as QuizModerate } from '../../assets/icons/quiz_moderate.svg';
import createPdf from '../../components/PDF/IDF/idfPDF';
import { authSelector } from '../../store/auth/auth.selectors';
import { orgSelector } from '../../store/organization/organization.selectors';
import { quizCertificate } from '../../store/api/calls/quiz.calls';
import { IDF_CERTIFICATE_QUIZ } from '../../store/quiz/quiz.constants';
import { getUserOrganization } from '../../store/api/calls/userOrganization.calls';
import { getFullName } from '../../utils/auth';
import BackButton from '../../components/shared/Buttons/BackButton';
import { finishQuizButtonDataSet } from './quizEnd.constants';

const certificateButtonStyle = {
  width: '200px',
};

const endButtonStyle = {
  backgroundColor: 'white',
  color: 'black',
};

const FEMALE = 'נקבה';

function QuizEnd() {
  const history = useHistory();
  const { id: quizId, multiChoiceUserChoices, questions } = useSelector(selectQuiz);
  const auth = useSelector(authSelector);
  const { id: ordId } = useSelector(orgSelector);
  const { showReport } = useSelector(selectedQuizSettingSelector);
  const { t } = useTranslation();
  const userOrg = useMemo(() => {
    return auth?.organizations.find(({ id }) => id === ordId);
  }, [auth, ordId]);

  const correctAnswers = useMemo(
    () => multiChoiceUserChoices.filter((bite) => bite.isCorrect),
    [multiChoiceUserChoices],
  );

  const score = useMemo(() => {
    return Math.round((correctAnswers.length / multiChoiceUserChoices.length) * 100);
  }, [correctAnswers.length, multiChoiceUserChoices.length]);

  //TODO: convert numbers to consts
  const getSummaryMessage = () => {
    if (score >= 80) {
      return t('screens.quiz.end.goodScoreMessage');
    } else if (score >= 60 && score < 80) {
      return t('screens.quiz.end.moderateScoreMessage');
    } else {
      return t('screens.quiz.end.badScoreMessage');
    }
  };

  const getSummaryColor = (theme) => {
    if (score >= 80) {
      return theme.colors.successGreen;
    } else if (score >= 60 && score < 80) {
      return theme.colors.moderateOrange;
    } else {
      return theme.colors.failRed;
    }
  };

  const handleDownload = useCallback(async () => {
    const { data: userOrgData } = await getUserOrganization(userOrg.user_organization_id);
    const { data } = await quizCertificate(quizId, auth.id);
    const gender = userOrgData?.['data1'].name === FEMALE ? 1 : 0;
    const username = getFullName(auth);

    createPdf({
      userName: username,
      israeliId: userOrg?.israeli_id,
      serialNumber: data.uuid,
      gender: gender,
    });
  }, [auth, quizId, userOrg?.israeli_id, userOrg?.user_organization_id]);

  const handleFinish = useCallback(() => {
    history.push('/feed');
  }, [history]);

  const handleBackButtonClick = useCallback(() => {
    const id = history.location.pathname.split('/')[2];
    const lastQuestionIndex = questions.length - 1;
    const previousQuestionId = questions[lastQuestionIndex]?.id;
    history.push(`/quiz/${id}/question/${previousQuestionId}${window.location.search}`);
  }, [history, questions]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.BackButtonStyled onClick={handleBackButtonClick} />
        {showReport && !isNaN(score) ? (
          <div>
            <S.SummaryText>
              {t('screens.quiz.end.summaryText', {
                correctAnswerCount: correctAnswers.length,
                scorableQuestionCount: multiChoiceUserChoices.length,
              })}
            </S.SummaryText>
            <S.SummaryBoldText>{t('screens.quiz.end.score')}</S.SummaryBoldText>
            <S.Score getSummaryColor={getSummaryColor}>{`${score}%`}</S.Score>
            <S.HorizontalDiv>
              {score >= 60 && (
                <S.IconContainer>{score >= 80 ? <S.QuizGoodSVG /> : <S.QuizModerateSVG />}</S.IconContainer>
              )}
              <S.SummaryMessage getSummaryColor={getSummaryColor}>{getSummaryMessage()}</S.SummaryMessage>
            </S.HorizontalDiv>
          </div>
        ) : (
          <S.SummaryBoldText>{t('screens.quiz.end.thanksMessage')}</S.SummaryBoldText>
        )}
        {quizId === IDF_CERTIFICATE_QUIZ &&
          auth &&
          (score >= 70 ? (
            <S.PassFrame>
              <S.Text>{t('certificate.passText', { score })}</S.Text>
              <S.Button onClick={handleDownload} style={certificateButtonStyle}>
                {t('certificate.downloadCertificate')}
              </S.Button>
            </S.PassFrame>
          ) : (
            <S.FailFrame>
              <S.Text>
                {t('certificate.lowScore', {
                  score,
                })}
              </S.Text>
            </S.FailFrame>
          ))}
        {quizId === IDF_CERTIFICATE_QUIZ && auth ? (
          <S.Button onClick={handleFinish} style={endButtonStyle}>
            {t('screens.quiz.end.finish')}
          </S.Button>
        ) : (
          <S.Button onClick={handleFinish} data-cy={finishQuizButtonDataSet.cy}>
            {t('screens.quiz.end.finish')}
          </S.Button>
        )}
      </S.Container>
    </S.Wrapper>
  );
}

const S = {
  Wrapper: styled.div`
    padding: 30px;
  `,
  BackButtonStyled: styled(BackButton)`
    margin-bottom: 20px;
  `,
  Container: styled.div`
    border-radius: 15px;
    padding: 5px 15px 24px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
    min-height: 500px;
    margin-top: 30px;
    ${() => getIsRtl() && ' direction: rtl; '};
    writing-mode: horizontal-tb;
  `,
  SummaryText: styled.div`
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    margin-bottom: 5px;
  `,
  SummaryBoldText: styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primaryBlue};
    margin-bottom: 40px;
  `,
  Score: styled.div<{ getSummaryColor }>`
    text-align: center;
    font-size: 64px;
    font-weight: 600;
    line-height: 24px;
    color: ${({ theme, getSummaryColor }) => getSummaryColor(theme)};
    margin-top: 5px;
    margin-bottom: 10px;
  `,
  SummaryMessage: styled.div<{ getSummaryColor }>`
    text-align: center;
    font-size: 32px;
    line-height: 24px;
    color: ${({ theme, getSummaryColor }) => getSummaryColor(theme)};
    padding-top: 20px;
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 20px;
  `,
  HorizontalDiv: styled.div`
    display: flex;
    flex-direction: horizontal;
    margin-top: 20px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
  `,
  IconContainer: styled.div`
    display: flex;
  `,
  QuizGoodSVG: styled(QuizGood)`
    width: 45px;
    height: auto;
  `,
  QuizModerateSVG: styled(QuizModerate)`
    width: 45px;
    height: auto;
  `,
  Button: styled(Button)`
    margin: 0 auto;
    width: 90px;
    height: 36px;
    margin-top: 20px;
  `,
  EndButton: styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  `,
  PassFrame: styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 200px;
    background-color: ${({ theme }) => theme.colors.lightGreen1};
    border: 1px solid ${({ theme }) => theme.colors.green1};
    border-radius: 15px;
  `,
  FailFrame: styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 200px;
    background-color: ${({ theme }) => theme.colors.lightRed1};
    border: 1px solid ${({ theme }) => theme.colors.red1};
    border-radius: 15px;
  `,
  Text: styled.div`
    text-align: center;
    margin: auto;
    margin: 20px;
  `,
};

export default QuizEnd;
