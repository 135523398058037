import { all } from 'redux-saga/effects';

import authSaga from './auth/auth.saga';
import ProfileSaga from './profile/profile.saga';
import biteSaga from './bite/bite.saga';
import playlistSaga from './playlist/playlist.saga';
import BiteShareUserSaga from './biteShareUser/biteShareUser.saga';
import organizationSaga from './organization/organization.saga';
import quizSaga from './quiz/quiz.saga';
import appActivitySaga from './appActivity/appActivity.saga';
import trackingSaga from './tracking/tracking.saga';
import knowledgeSaga from './knowledge/knowledge.saga';
import geofenceSaga from './geofence/geofence.saga';
import feedSaga from './feed/feed.saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    biteSaga(),
    playlistSaga(),
    BiteShareUserSaga(),
    ProfileSaga(),
    organizationSaga(),
    quizSaga(),
    knowledgeSaga(),
    appActivitySaga(),
    trackingSaga(),
    geofenceSaga(),
    feedSaga(),
  ]);
}
