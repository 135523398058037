import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import styled, { css, useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ErrorIcon } from '../../../assets/icons/error-403.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close-modal.svg';
import { ContinueButton } from '../Buttons';
import { authCommonSelector } from '../../../store/auth/auth.selectors';
import { addUserToOrganization } from '../../../store/api/calls/auth.calls';
import { getOrgSettings } from '../../../store/organization/organization.slice';
import { setIsRestrictedAllowJoinModalShown, refreshUser } from '../../../store/auth/auth.actions';
import { getRedirectUri, setRedirectUri } from '../../../utils/redirection';
import useCustomTranslation from '../../../hooks/useCustomTranslation';
import InputWithIcon from '../Inputs/InputWithIcon';
import { log, logError } from '../../../store/tracking/tracking.slice';
import { cloneDeep } from 'lodash';
import {
  accessCodeContinueButtonDataSet,
  accessCodePlaceHolderDataSet,
  errorMessageDataSet,
} from './onlyTeamContentModal.constants';

interface IProps {
  onCancel?: (any) => void;
}

const OnlyTeamContentModal: FC<IProps> = (props) => {
  const { onCancel } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { prefixedT } = useCustomTranslation('screens.auth.SecuredContent');
  const [accessCode, setAccessCode] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isWrongCode, setIsWrongCode] = useState(false);
  const theme = useTheme();
  const { isRestrictedAllowJoinModalShown } = useSelector(authCommonSelector);

  const closeModalHandler = () => {
    dispatch(
      log({
        event: 'OnlyTeamContentModal.closeModalHandler',
      }),
    );
    dispatch(setIsRestrictedAllowJoinModalShown(false));
    setRedirectUri({ redirectUri: null, replace: true });
  };

  const onCodeChanged = (evt) => {
    setAccessCode(evt.target.value);
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    try {
      dispatch(
        log({
          event: 'OnlyTeamContentModal.handleSubmit: start',
        }),
      );

      const {
        data: { organization },
      } = await addUserToOrganization(accessCode);

      dispatch(
        log({
          event: 'OnlyTeamContentModal.handleSubmit: addUserToOrganization done',
        }),
      );

      dispatch(getOrgSettings(organization));
      dispatch(refreshUser());
      dispatch(setIsRestrictedAllowJoinModalShown(false));

      const redirectURL = getRedirectUri();
      if (redirectURL) {
        history.push(redirectURL);
      } else {
        history.push('/feed');
      }
    } catch (error) {
      dispatch(
        logError({
          event: 'OnlyTeamContentModal.handleSubmit: error',
          data: {
            error,
            errorResponse: cloneDeep(error?.response),
          },
        }),
      );

      setIsWrongCode(true);
      //setError(err.message)
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isRestrictedAllowJoinModalShown}
      onRequestClose={onCancel}
      style={{
        overlay: {
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: theme.colors.lightGray14,
          padding: 0,
          paddingTop: '30px',
          paddingBottom: '5px',
          borderRadius: 20,
          border: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: '90%',
          width: '340px',
          height: 'auto',
          boxShadow: '0 12px 19px 0 rgba(60, 128, 209, 0.09)',
        },
      }}
    >
      <ErrorIcon width={80} height={80} />
      <S.MainTitle>{prefixedT('securedContent')}</S.MainTitle>
      <S.Divider />
      <S.HelperText>{prefixedT('cannotView')}</S.HelperText>
      <InputWithIcon
        onChange={onCodeChanged}
        placeholder={prefixedT('accessCodePlaceholder')}
        inputWidth={250}
        data-cy={accessCodePlaceHolderDataSet.cy}
      />
      {isWrongCode && (
        <S.ErrorMessage data-cy={errorMessageDataSet.cy}>{prefixedT('invalidAccessCode')}</S.ErrorMessage>
      )}
      <S.NextButton
        disabled={!accessCode || isProcessing}
        onClick={handleSubmit}
        data-cy={accessCodeContinueButtonDataSet.cy}
      />
      <S.DismissText onClick={closeModalHandler}>{prefixedT('dismissText')}</S.DismissText>
    </Modal>
  );
};

const S = {
  MainTitle: styled.span`
    margin-top: 20px;
    margin-bottom: 2px;
    text-align: center;
    font-size: 19px;
    letter-spacing: 0.8px;
    ${({ theme }) => css`
      color: ${theme.colors.darkPrimary};
    `}
  `,
  HelperText: styled.span`
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    ${({ theme }) => css`
      color: ${theme.colors.darkPrimary};
    `}
  `,
  DismissText: styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    ${({ theme }) => css`
      color: ${theme.colors.primaryBlue};
    `}
  `,
  CloseModalIcon: styled(CloseIcon)`
    width: 32px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
  `,
  NextButton: styled(ContinueButton)`
    margin-top: 10px;
    margin-bottom: 10px;
    width: 160px;
  `,
  ErrorMessage: styled.div`
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 15px;
    letter-spacing: 0.2px;
    ${({ theme }) => css`
      color: ${theme.colors.failRed};
    `}
  `,
  Divider: styled.div(
    ({ theme }) => css`
      margin: 20px 0;
      width: 100%;
      height: 1px;
      background-color: ${theme.colors.lightGray12};
      @media ${theme.breakPoints.mobileL} {
        display: none;
      }
    `,
  ),
};

export default OnlyTeamContentModal;
