import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as BitesLogoIcon } from '../../../assets/icons/bites-logo.svg';
import { organizationBrandingSelector } from '../../../store/organization/organization.selectors';
import styled from 'styled-components';
import { AWS_URL } from '../../../constants/app';

interface IProps {
  width?: number;
  height?: number;
}

const OrgLogo: FC<IProps> = ({ width = 91, height = 49 }) => {
  const organizationBranding = useSelector(organizationBrandingSelector);

  if (organizationBranding?.logo_url) {
    return (
      <S.LogoContainer width={width} height={height}>
        <S.Logo src={`${AWS_URL}/${organizationBranding.logo_url}`} alt='logo' />
      </S.LogoContainer>
    );
  }

  return (
    <S.LogoContainer width={width} height={height}>
      <BitesLogoIcon />
    </S.LogoContainer>
  );
};

const S = {
  LogoContainer: styled.div<{ width?: number; height?: number }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ width }) => width}px;
    height: ${({ height }) => height}px;
  `,
  Logo: styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
  `,
};

export default memo(OrgLogo);
