import { useSelector } from 'react-redux';
import { selectedPlaylistSelector } from '../../../../store/playlist/playlist.selectors';
import { selectedBiteSelector } from '../../../../store/bite/bite.selectors';
import { authSelector, isAuthenticatedSelector } from '../../../../store/auth/auth.selectors';
import { useCallback, useMemo } from 'react';
import { getNextPlaylistItem } from '../utils';
import { PUBLIC_FEED_ROUTES } from '../../../../navigation/Routes';
import useCustomHistory from '../../../../hooks/useCustomHistory';
import useQueryParams from '../../../../hooks/useQueryParams';

const useGoToNext = () => {
  const { selectedPlaylist } = useSelector(selectedPlaylistSelector);
  const { selectedBite } = useSelector(selectedBiteSelector);
  const auth = useSelector(authSelector);
  const { queryParams } = useQueryParams();
  const isPreview = queryParams?.isPreview;
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const { history, historyPushWithPersistedQueryParams } = useCustomHistory();

  const shouldRedirectToHome = useMemo(() => {
    return (
      !selectedPlaylist ||
      selectedBite?.id === selectedPlaylist?.bite_shares[selectedPlaylist?.bite_shares.length - 1]?.id
    );
  }, [selectedBite?.id, selectedPlaylist]);

  const handleGetNextPlaylistItem = useCallback(() => {
    getNextPlaylistItem(history, selectedPlaylist, selectedBite, auth);
  }, [history, selectedPlaylist, selectedBite, auth]);

  const handleRedirectToFeed = useCallback(() => {
    const publicFeedRoute = PUBLIC_FEED_ROUTES.find((route) => route.orgId === parseInt(selectedBite?.orgid, 10));
    if (publicFeedRoute) {
      historyPushWithPersistedQueryParams(publicFeedRoute.path);
      return;
    }

    history.push('/', { hasFinishedContent: true });
  }, [history, historyPushWithPersistedQueryParams, selectedBite?.orgid]);

  const handleNext = useCallback(() => {
    if (shouldRedirectToHome) {
      if (isAuthenticated && !isPreview) {
        handleRedirectToFeed();
      }
      return;
    }

    handleGetNextPlaylistItem();
  }, [shouldRedirectToHome, isAuthenticated, isPreview, handleGetNextPlaylistItem, handleRedirectToFeed]);

  return { handleNext, shouldRedirectToHome };
};

export default useGoToNext;
