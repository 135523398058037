import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { BaseTextArea } from '../shared/Inputs/TextArea';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as AddPhotoIcon } from '../../assets/icons/add-photo.svg';
import { Button } from '../shared';
import { getIsRtl } from '../../locale/i18n';
// import { IMAGE_MAX_SIZE } from '../../utils/constants'
import { Tooltip } from 'react-tooltip';
import { isAuthenticatedSelector } from '../../store/auth/auth.selectors';
import { openEndedTextAreaDataSet, openEndedSubmitDataSet } from './openEndedAnswer.constants';

interface Props {
  isProcessing?: boolean;
  text: string;
  onTextChange: (evt: any) => void;
  image: any;
  onImageChange: (image: any) => void;
  onSubmit: () => void;
  hideContinue?: boolean;
  isAnswered: boolean;
}

const inputStyle = { display: 'none' };
const OPEN_ENDED_ADD_IMAGE = 'openEndedAddImage';

const OpenEndedAnswer: React.FC<Props> = ({
  isProcessing,
  text,
  onTextChange,
  image,
  onImageChange,
  onSubmit,
  hideContinue,
  isAnswered,
}) => {
  const { t } = useTranslation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const didAnswerAlreadyOnlyWithImage = isAnswered && !text;

  const handleChangeImage = (e) => {
    const { files } = e.target;
    if (files.length === 0) {
      // user clicked image picker and canceled
      return;
    }
    onImageChange(files[0]);
    // if (files[0].size < IMAGE_MAX_SIZE) {
    //   onImageChange(files[0])
    // }
  };

  return (
    <>
      {!didAnswerAlreadyOnlyWithImage && (
        <S.TextAreaContainer>
          <S.TextArea
            rows={5}
            onChange={onTextChange}
            value={text}
            disabled={isAnswered}
            placeholder={t('components.openEndedAnswer.placeholder')}
            id='openEndedTextArea'
            data-cy={openEndedTextAreaDataSet.cy}
          />
          {!isAnswered && (
            <S.AddPhotoContainer id={OPEN_ENDED_ADD_IMAGE} data-tooltip-content={t('common.isNotAuthenticated')}>
              <S.AddPhotoIcon />
              {isAuthenticated ? (
                <input
                  onChange={handleChangeImage}
                  type='file'
                  accept='image/jpeg, image/png, image/jpg'
                  style={inputStyle}
                />
              ) : (
                <S.Tooltip anchorId={OPEN_ENDED_ADD_IMAGE} />
              )}
            </S.AddPhotoContainer>
          )}
        </S.TextAreaContainer>
      )}
      {image && (
        <S.OpenEndedPreviewImageContainer>
          <S.OpenEndedPreviewImage alt='preview' src={typeof image === 'string' ? image : URL.createObjectURL(image)} />
          {!isAnswered && (
            <S.OpenEndedPreviewImageClose id='openEndedRemoveImage' onClick={() => onImageChange(null)} />
          )}
        </S.OpenEndedPreviewImageContainer>
      )}
      {!hideContinue && (
        <Button
          id='openEndedSubmit'
          data-cy={openEndedSubmitDataSet.cy}
          onClick={onSubmit}
          disabled={(!text && !image) || isProcessing}
          isLoading={isProcessing}
        >
          {t('common.CONTINUE').toUpperCase()}
        </Button>
      )}
    </>
  );
};

const S = {
  TextAreaContainer: styled.div`
    overflow: hidden;
    border: 1px solid #eee;
    margin-bottom: 20px;
    border-radius: 15px;
    box-shadow: 0 12px 19px 0 rgba(60, 128, 209, 0.09);
  `,
  TextArea: styled(BaseTextArea)`
    border: none;
    border-bottom: 1px solid #f5f5f5;
    outline: none;
    width: 100%;
    padding: 20px 25px;
    direction: ${() => (getIsRtl() ? 'rtl' : 'ltr')};
    ${() => getIsRtl() && 'text-align: right; !important'};
    writing-mode: horizontal-tb;
  `,
  Tooltip: styled(Tooltip)`
    ${({ theme }) => css`
      background: ${theme.colors.primaryBlue};
    `}
  `,
  AddPhotoContainer: styled.label`
    display: block;
    border-radius: 50%;
    background: #909090;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -4px 0 1px 1px;
    cursor: pointer;
    font-size: 14px;
  `,
  AddPhotoIcon: styled(AddPhotoIcon)`
    width: 25px;
    height: 25px;
    margin-top: -3px;
  `,
  OpenEndedPreviewImageContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  `,
  OpenEndedPreviewImage: styled.img`
    max-height: 150px;
    max-width: 100%;
  `,
  OpenEndedPreviewImageClose: styled(CloseIcon)`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
  `,
};

export default OpenEndedAnswer;
