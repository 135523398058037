import { RootState } from '..';

export const orgSelector = (state: RootState) => state.organization;
export const orgIdSelector = (state: RootState) => state.organization.id;

export const allowedAuthMethodsSelector = (state: RootState) => orgSelector(state).allowedAuthMethods;
export const organizationBrandingSelector = (state: RootState) => orgSelector(state).branding;
export const signupEnabledSelector = (state: RootState) => orgSelector(state).signupEnabled;
export const popularLocalesSelector = (state: RootState) => orgSelector(state).popularLocales.data;
export const popularLocalesIsLoadingSelector = (state: RootState) => orgSelector(state).popularLocales.isLoading;
export const popularLocalesErrorSelector = (state: RootState) => orgSelector(state).popularLocales.error;
