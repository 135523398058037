import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { log } from '../../store/tracking/tracking.slice';
import { resetAuthErrors } from '../../store/auth/auth.actions';
import { AMAZON_AUTH_URL } from '../../screens/auth/common/socialButtons.constants';

const useAmazonLogin = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(
      log({
        event: 'useAmazonLogin',
      }),
    );
    dispatch(resetAuthErrors());

    const currentUrl = window.location.href;
    window.location.href = `${AMAZON_AUTH_URL}?${encodeURIComponent(currentUrl)}`;
  }, [dispatch]);
};

export default useAmazonLogin;
