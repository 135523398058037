import { useDispatch, useSelector } from 'react-redux';
import {
  orgIdSelector,
  popularLocalesErrorSelector,
  popularLocalesIsLoadingSelector,
  popularLocalesSelector,
} from '../store/organization/organization.selectors';
import { useEffect } from 'react';
import { getPopularLocales } from '../store/organization/organization.slice';
import store from '../store/store';

const DEFAULT_POPULAR_LANGS = ['en', 'es', 'ar', 'ru'];

export const usePopularLocales = () => {
  const dispatch = useDispatch();
  const orgId = useSelector(orgIdSelector);
  const popularLocales = useSelector(popularLocalesSelector);
  const popularLocalesIsLoading = useSelector(popularLocalesIsLoadingSelector);
  const popularLocalesError = useSelector(popularLocalesErrorSelector);

  useEffect(() => {
    // might be trigered in parallel from several places
    // take the current state of the store
    // not the one that was in the scope during rendering
    const { isLoading } = store.getState().organization.popularLocales;

    if (isLoading) {
      return;
    }

    if (!popularLocales) {
      dispatch(getPopularLocales());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgId]);

  return {
    popularLocales: popularLocales || DEFAULT_POPULAR_LANGS,
    isLoading: popularLocalesIsLoading,
    error: popularLocalesError,
  };
};
