import { useMemo } from 'react';
import { DTDataWithState } from '../store/slice.helpers';

export const useDTListCombinedLoading = <T>(dtList: DTDataWithState<T>[]) => {
  const isBlockLoading = useMemo(() => {
    return dtList?.some(({ isLoading }) => isLoading);
  }, [dtList]);

  return isBlockLoading;
};
