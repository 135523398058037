import React, { FC } from 'react';
import styled from 'styled-components';

import { ContinueButton } from '../../../components/shared';
import useGoToNext from './hooks/useGoToNext';
import useQueryParams from '../../../hooks/useQueryParams';
import { useSelector } from 'react-redux';
import { isAuthenticatedSelector } from '../../../store/auth/auth.selectors';

const GoNextBlueButton: FC = () => {
  const { handleNext, shouldRedirectToHome } = useGoToNext();
  const { queryParams } = useQueryParams();
  const isPreview = queryParams?.isPreview;
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  if ((isPreview && shouldRedirectToHome) || (!isAuthenticated && shouldRedirectToHome)) {
    return null;
  }

  return <S.ContinueButton onClick={handleNext} />;
};

const S = {
  ContinueButton: styled(ContinueButton)`
    margin-top: 20px;
  `,
};

export default GoNextBlueButton;
