import React, { FC } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import type { RouteChildrenProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import ControllersBar from './components/ControllersBar';
// import useHomeScreenLogic from './useHomeScreenLogic';

import ErrorModal from '../../components/shared/CustomModal/ErrorModal';
import { ReactComponent as FeedLoader } from '../../assets/loaders/feed-loader.svg';
// import InfinitePlaylistList from '../../components/playlist';
// import { playlistsSelector } from '../../store/playlist/playlist.selectors';
// import { bitesSelector } from '../../store/bite/bite.selectors';
// import InfiniteBiteList from '../../components/bite/InfiniteBiteList';
import { HEADER_HEIGHT } from '../../components/layout/StyledHeader';
import OnlyTeamContentModal from '../../components/shared/CustomModal/OnlyTeamContentModal';
import NoContentModal from '../../components/shared/CustomModal/NoContentModal';
// import gtmTrack from '../../services/googleTagManager/track';
import useRemoveQueryParams from '../../hooks/useRemoveQueryParams';
import AskMeAnythingBottomSheet from './components/AskMeAnythingBottomSheet';
import { CONTROLLERS_BAR_HEIGHT } from './Home.constants';
import { authSelector } from '../../store/auth/auth.selectors';
import Feed from '../Feed/Feed';
import CustomIntercomLauncher from '../../components/shared/CustomIntercomLauncher';
import { orgSelector } from '../../store/organization/organization.selectors';
import { organizationHasFeatureFlagSelector } from '../../store/profile/profile.selectors';
import { Features } from '../../utils/featureFlag/featureFlag.types';

const Home: FC<RouteChildrenProps> = () => {
  // const { handleBiteClick, handlePlaylistClick } = useHomeScreenLogic();
  const location = useLocation<{ hasFinishedContent?: boolean }>();
  const auth = useSelector(authSelector);
  const { id: orgId } = useSelector(orgSelector);
  const askAiFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.ASK_AI_FEED,
    }),
  );

  useRemoveQueryParams(['timestamp']);

  return (
    <>
      <ErrorModal />
      <NoContentModal />
      <OnlyTeamContentModal />
      {location.state?.hasFinishedContent && (
        <S.WellDoneContainer>
          <S.WellDoneText>Well done{auth?.first_name ? `, ${auth.first_name}!` : '!'}</S.WellDoneText>
        </S.WellDoneContainer>
      )}
      <Feed />
      {/*<ControllersBar />*/}
      {/*<S.Feed>*/}
      {/*  <Route*/}
      {/*    path='/bites' //*/}
      {/*    component={() => <Bites onBiteClick={handleBiteClick} />}*/}
      {/*  />*/}
      {/*  <Route*/}
      {/*    path='/playlists' //*/}
      {/*    component={() => <Playlists onPlaylistClick={handlePlaylistClick} />}*/}
      {/*  />*/}
      {/*</S.Feed>*/}
      <CustomIntercomLauncher marginBottom={askAiFeatureFlag && 75} />
      <AskMeAnythingBottomSheet />
    </>
  );
};

// const Bites = ({ onBiteClick }: { onBiteClick: (biteShareId: number, subject: string) => void }) => {
//   const { isLoading: isBitesLoading } = useSelector(bitesSelector);
//
//   useEffect(() => {
//     gtmTrack('pageview', {
//       page_title: 'Home',
//       tab: 'bites',
//     });
//   }, []);
//
//   return isBitesLoading ? <S.FeedLoader /> : <InfiniteBiteList onBiteClick={onBiteClick} />;
// };
//
// const Playlists = ({ onPlaylistClick }: { onPlaylistClick: (playlist: any) => void }) => {
//   const { isLoading: isPlaylistLoading } = useSelector(playlistsSelector);
//
//   useEffect(() => {
//     gtmTrack('pageview', {
//       page_title: 'Home',
//       tab: 'playlists',
//     });
//   }, []);
//
//   return isPlaylistLoading ? <S.FeedLoader /> : <InfinitePlaylistList onPlaylistClick={onPlaylistClick} />;
// };

const FEED_TOP_PADDING = 30;

const FEED_LOADER_TOP_OFFSET = HEADER_HEIGHT + CONTROLLERS_BAR_HEIGHT + FEED_TOP_PADDING;
const FEED_LOADER_HEIGHT = 75;

const S = {
  Feed: styled.div`
    padding-top: ${FEED_TOP_PADDING};
    width: 100%;
    height: 100%;
  `,
  FeedLoader: styled(FeedLoader)`
    margin: ${(window.innerHeight - FEED_LOADER_TOP_OFFSET) / 2 - FEED_LOADER_HEIGHT / 2}px auto 0;
    height: ${FEED_LOADER_HEIGHT}px;
    display: block;
  `,
  WellDoneContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0;
  `,
  WellDoneText: styled.div`
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.doneGreen};
  `,
};

export default Home;
