import { createSelector } from 'reselect';

import { RootState } from '..';

const playlistCommonSelector = (state) => state.playlistReducer;

export const playlistsSelector = createSelector(playlistCommonSelector, (item) => item.playlists || []);
export const playlistsLoadingSelector = (state: RootState) => state.playlistReducer.playlists.isLoading;
export const selectedPlaylistSelector = (state: RootState) => state.playlistReducer.selectedPlaylist;
export const playlistsMapSelector = (state: RootState) => state.playlistReducer.playlists.playlistsMap;
