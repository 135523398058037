import React, { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as HelpCenterIcon } from '../../assets/icons/help-center.svg';
import { useIntercom } from 'react-use-intercom';
import { getIsRtl } from '../../locale/i18n';
import { isMobile } from '../../utils/utils';
import useQueryParams from '../../hooks/useQueryParams';
import { useSelector } from 'react-redux';
import { organizationHasFeatureFlagSelector } from '../../store/profile/profile.selectors';
import { Features } from '../../utils/featureFlag/featureFlag.types';
import { orgSelector } from '../../store/organization/organization.selectors';

interface IProps {
  marginBottom?: number;
  position?: 'left' | 'right';
}

const CustomIntercomLauncher: React.FC<IProps> = ({ marginBottom = 10, position = 'right' }) => {
  const isRtl = getIsRtl();
  const isMobileDevice = isMobile();
  const { showNewMessages } = useIntercom();
  const [intercomReady, setIntercomReady] = useState(false);
  const { queryParams } = useQueryParams();
  const isPreview = queryParams?.isPreview;

  const { id: orgId } = useSelector(orgSelector);
  const intercomUpdateFeatureFlag = useSelector(
    organizationHasFeatureFlagSelector({
      orgId,
      feature: Features.INTERCOM_UPDATE,
    }),
  );

  useEffect(() => {
    const checkIntercom = setInterval(() => {
      if (window.Intercom && typeof window.Intercom === 'function') {
        setIntercomReady(true);
        clearInterval(checkIntercom);
      }
    }, 500);

    if (window.Intercom && typeof window.Intercom === 'function') {
      setIntercomReady(true);
      clearInterval(checkIntercom);
    }
    return () => clearInterval(checkIntercom);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIntercomClick = useCallback(() => {
    showNewMessages();
  }, [showNewMessages]);

  if (!intercomReady || isPreview || !intercomUpdateFeatureFlag) {
    return null;
  }

  return (
    <S.Container
      isMobileDevice={isMobileDevice}
      isRtl={isRtl}
      marginBottom={marginBottom}
      position={position}
      onClick={handleIntercomClick}
    >
      <HelpCenterIcon />
    </S.Container>
  );
};

const S = {
  Container: styled.div<{
    marginBottom?: number;
    position: 'left' | 'right';
    isRtl?: boolean;
    isMobileDevice?: boolean;
  }>`
    position: fixed;
    display: flex;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    cursor: pointer;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;

    box-shadow: 0 5px 19px 0 rgba(0, 0, 0, 0.1);

    bottom: ${({ marginBottom, isMobileDevice }) => (isMobileDevice ? marginBottom : 20)}px;
    ${({ position, isRtl, isMobileDevice }) =>
      isMobileDevice
        ? position === 'left'
          ? `${isRtl ? 'right' : 'left'}: 10px`
          : `${isRtl ? 'left' : 'right'}: 10px`
        : 'right: 20px'};
  `,
};

export default memo(CustomIntercomLauncher);
